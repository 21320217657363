import React from "react";
import styled from "styled-components";

import { UserDemographic } from "context/UserProfileContext";
import { useAppSelector } from "store";

import { Text } from "components/Text";
import { Select } from "components/_inputs/Select";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { RadioGroup } from "components/_inputs/RadioGroup";
import { CustomRedactionConditionSection } from "./CustomRedactionConditionSection";

import { Color } from "ts/enums/color";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { RedactionCustomCondition } from "ts/sharing";
import { DemographicFilterHeader } from "ts/filters/demographicFilter";

import { RedactionVersion } from "ts/redaction";
import { ManualRedactionPermission } from "@explorance/mly-types";
import { RadioOption } from "ts/radio";
import { useResource } from "hooks/useResource";

type Props = {
  analysisDemographicHeaders: DemographicFilterHeader[];
  selectedCustomConditions: RedactionCustomCondition[];
  userDemographics: UserDemographic[];
  selectedRedactionVersion: RedactionVersion;
  showRedactionCustomConditionError: boolean;
  selectedManualRedactionPermission: ManualRedactionPermission;
  updateManualRedactionPermission: (selectedManualRedactionPermission: RadioOption) => void;
  updateRedactionCustomConditions: (
    updatedCustomConditions: RedactionCustomCondition[],
    noSave?: boolean
  ) => void;
  updateSelectedRedactionVersion: (selectedRedactionVersionId: string) => void;
  handleHideRedactionCustomConditionError: () => void;
};

export const VisibleRedactionVersion = ({
  analysisDemographicHeaders,
  selectedCustomConditions,
  userDemographics,
  selectedRedactionVersion,
  showRedactionCustomConditionError,
  selectedManualRedactionPermission,
  updateManualRedactionPermission,
  updateRedactionCustomConditions,
  updateSelectedRedactionVersion,
  handleHideRedactionCustomConditionError,
}: Props) => {
  const state = useAppSelector((state) => state.sharing);

  const { getResource } = useResource();

  const redactionVersionDropdownOptions = state.redactionVersions?.map((version) => ({
    label: version.name,
    value: version.id?.toString(),
  }));

  const radioOptions = [
    {
      label: <Text resource="redaction.sharingPermissions.radioOption.cantRedact" />,
      value: ManualRedactionPermission.Denied,
    },
    {
      label: <Text resource="redaction.sharingPermissions.radioOption.allowRedactionLimited" />,
      value: ManualRedactionPermission.ApprovalNeeded,
    },
    {
      label: <Text resource="redaction.sharingPermissions.radioOption.allowRedaction" />,
      value: ManualRedactionPermission.Allowed,
    },
  ];

  const addCustomCondition = () => {
    updateRedactionCustomConditions(
      [
        ...(selectedCustomConditions ? selectedCustomConditions : []),
        { redactionVersionId: null, userDemographicValues: [], userDemographic: "" },
      ],
      false
    );
  };

  return (
    <>
      <StyledTitleContainer>
        <Text resource="sharing.permissionForm.redactionVersion.label" />
      </StyledTitleContainer>
      <StyledRedactionBlockContainer>
        <StyledFirstConditionRow>
          <StyledDropdownContainer>
            <Select
              options={redactionVersionDropdownOptions}
              selectedOption={{
                label: selectedRedactionVersion?.name || getResource("dropdown.none"),
                value: `${selectedRedactionVersion?.id}` || null,
              }}
              handleChange={updateSelectedRedactionVersion}
              buttonStyle={{ width: "100%" }}
              selectStyle={{ width: "100%" }}
              dropdownWidth="100%"
              addNoneOption
            />
          </StyledDropdownContainer>
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            style={{ height: "28px", gap: "6px", whiteSpace: "nowrap" }}
            onClick={addCustomCondition}
          >
            <Icon type={IconType.gear} size={12} />
            <Text resource="button.addCustomCondition" />
          </Button>
        </StyledFirstConditionRow>
        {selectedCustomConditions?.length > 0 && (
          <CustomRedactionConditionSection
            showRedactionCustomConditionError={showRedactionCustomConditionError}
            savedCustomConditions={selectedCustomConditions}
            analysisDemographicHeaders={analysisDemographicHeaders}
            userDemographics={userDemographics}
            updateRedactionCustomConditions={updateRedactionCustomConditions}
            handleHideRedactionCustomConditionError={handleHideRedactionCustomConditionError}
          />
        )}
        <StylesRadioGroupContainer>
          <StyledRadioLabel>
            <Text resource="redaction.sharingPermissions.radioLabel" />
          </StyledRadioLabel>
          <RadioGroup
            selectedOption={
              radioOptions.find((option) => option.value === selectedManualRedactionPermission) ??
              radioOptions[0]
            }
            options={radioOptions}
            onChange={updateManualRedactionPermission}
            customStyles={{ radioLabelWidth: "360px" }}
          />
        </StylesRadioGroupContainer>
      </StyledRedactionBlockContainer>
    </>
  );
};

const StyledRedactionBlockContainer = styled.div`
  background-color: ${Color.neutral10};
  border-radius: 5px;
  padding: 12px 24px;
  margin-bottom: 16px;
`;

const StyledTitleContainer = styled.div`
  font-weight: bold;
  font-size: 15px;
  padding-bottom: 6px;
`;

const StyledDropdownContainer = styled.div`
  width: 84%;
  margin-bottom: 6px;
`;

const StyledRadioLabel = styled.div`
  color: ${Color.gray50};
  font-size: 15px;
  font-weight: bold;
`;

const StyledFirstConditionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const StylesRadioGroupContainer = styled.div`
  margin-top: 6px;
`;
