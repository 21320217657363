import React from "react";
import styled from "styled-components";

import { UserDemographic } from "context/UserProfileContext";
import { useResource } from "hooks/useResource";

import { VisibleAnalysisData } from "./VisibleAnalysisData";
import { VisibleDemographicsSelection } from "./VisibleDemographicsSelection";
import { CheckboxField } from "components/_inputs/CheckboxField";
import { StyledHorizontalField } from "./PermissionFormContainer";
import { Select } from "components/_inputs/Select";
import { Text } from "components/Text";
import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { VisibleRedactionVersion } from "./VisibleRedactionVersion";

import {
  SharingDashboardAccess,
  SharingTopicExplorerAccess,
  SharingCommentExplorerAccess,
  PermissionLevel,
  ManualRedactionPermission,
} from "@explorance/mly-types";
import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import { DemographicCustomCondition, RedactionCustomCondition } from "ts/sharing";
import { SelectOption } from "ts/select";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { EditGroupModalView } from "ts/enums/editGroupModalView";
import { Color } from "ts/enums/color";
import { RedactionVersion } from "ts/redaction";
import { RadioOption } from "ts/radio";
import { IsFeatureActive } from "utils/isFeatureActive";
import { Feature } from "ts/enums/feature";

type Props = {
  showPermissionFields: boolean;
  analysisDemographicHeaders: DemographicFilterHeader[];
  permissionLevelData: {
    selectedPermissionLevel: PermissionLevel;
    handleSelectPermissionLevel: (permissionLevel: PermissionLevel) => void;
  };
  visibleAnalysisData: {
    selectedDemographics: DemographicFilter[];
    selectedCustomConditions: DemographicCustomCondition[];
    showCustomConditionError: boolean;
    userDemographics: UserDemographic[];
    // function props
    updateSelectedDemographics: (updatedValues: DemographicFilter[]) => void;
    updateDemographicCustomConditions: (
      updatedCustomConditions: DemographicCustomCondition[],
      noSave?: boolean
    ) => void;
    handleHideCustomConditionError: () => void;
  };
  visibleDemographicsData: {
    savedDemHeaders: DemographicFilterHeader[];
    updateSavedDemHeaders: (updatedHeaders: DemographicFilterHeader[]) => void;
  };
  visibleRedactionVersionData: {
    selectedCustomConditions: RedactionCustomCondition[];
    userDemographics: UserDemographic[];
    selectedRedactionVersion: RedactionVersion;
    showRedactionCustomConditionError: boolean;
    selectedManualRedactionPermission: ManualRedactionPermission;
    updateManualRedactionPermission: (selectedManualRedactionPermission: RadioOption) => void;
    updateRedactionCustomConditions: (
      updatedCustomConditions: RedactionCustomCondition[],
      noSave?: boolean
    ) => void;
    updateSelectedRedactionVersion: (selectedRedactionVersionId: string) => void;
    handleHideRedactionCustomConditionError: () => void;
  };
  dashboardSettingsData: {
    dashboardAccess: SharingDashboardAccess;
    topicExplorerAccess: SharingTopicExplorerAccess;
    commentExplorerAccess: SharingCommentExplorerAccess;
    allowExportData: boolean;
    allowPin: boolean;
    // function props
    handleAllowPin: () => void;
    handleSelectDashboardAccess: () => void;
    handleSelectCommentExplorerAccess: () => void;
    handleSelectTopicExplorerAccess: () => void;
    handleToggleExportData: () => void;
  };
  editSharing?: boolean;
  usersSharedWithCount?: number;
  showEditAddUsers?: boolean;
  closeEditSharing?: () => void;
  applyEditChanges?: () => void;
  navigateToPreview?: () => void;
  openEditGroupModal?: (view: EditGroupModalView) => void;
  isLoading?: boolean;
};

export const PermissionForm = ({
  showPermissionFields,
  analysisDemographicHeaders,
  permissionLevelData,
  visibleAnalysisData,
  visibleDemographicsData,
  visibleRedactionVersionData,
  dashboardSettingsData,
  editSharing,
  usersSharedWithCount,
  showEditAddUsers = false,
  closeEditSharing,
  applyEditChanges,
  navigateToPreview,
  openEditGroupModal,
  isLoading,
}: Props) => {
  const { selectedPermissionLevel, handleSelectPermissionLevel } = permissionLevelData;

  const { getResource } = useResource();
  const {
    dashboardAccess,
    topicExplorerAccess,
    commentExplorerAccess,
    allowExportData,
    allowPin,
    // function props
    handleSelectDashboardAccess,
    handleSelectTopicExplorerAccess,
    handleSelectCommentExplorerAccess,
    handleAllowPin,
    handleToggleExportData,
  } = dashboardSettingsData;

  const checkboxFieldStyle = { marginLeft: "-5px", labelFontSize: "15px" };
  const isRedactionFeatureActive = IsFeatureActive(Feature.Redaction);

  const permissionLevelDropdownOptions: SelectOption[] = [
    { label: getResource("sharing.permissionLevelDropdown.viewer"), value: PermissionLevel.View },
    {
      label: getResource("sharing.permissionLevelDropdown.contributor"),
      value: PermissionLevel.Contribution,
    },
  ];

  return (
    <div className="fade-in">
      <StyledHorizontalField
        marginBottom={!showPermissionFields && "0"}
        editSharing={editSharing && showEditAddUsers}
      >
        {showEditAddUsers && (
          <StyledUsersSharedWithBlock>
            <span>
              <Text
                resource={{
                  key:
                    usersSharedWithCount === 1
                      ? "sharing.permissionForm.usersSharedWithCount.singular"
                      : "sharing.permissionForm.usersSharedWithCount",
                  args: [`${usersSharedWithCount}`],
                }}
              />
            </span>
            <StyledButtonGroup>
              <Button
                variant={ButtonVariant.outlineBlue}
                size={ButtonSize.sm}
                onClick={() => openEditGroupModal(EditGroupModalView.edit)}
              >
                <Text resource="button.editUsers" />
              </Button>
              <Button
                variant={ButtonVariant.outlineBlue}
                size={ButtonSize.sm}
                onClick={() => openEditGroupModal(EditGroupModalView.add)}
              >
                <Text resource="button.addMore" />
              </Button>
            </StyledButtonGroup>
          </StyledUsersSharedWithBlock>
        )}
        <label htmlFor="permission-level">
          <Text resource="sharing.permissionForm.permissionLevel.label" />
          {""}
        </label>
        <Select
          options={permissionLevelDropdownOptions}
          selectedOption={permissionLevelDropdownOptions.find(
            (o) => o.value === selectedPermissionLevel
          )}
          handleChange={handleSelectPermissionLevel}
          buttonStyle={{ minWidth: 130 }}
          dropdownWidth="130px"
        />
      </StyledHorizontalField>
      {showPermissionFields && (
        <>
          <VisibleAnalysisData
            {...visibleAnalysisData}
            analysisDemographicHeaders={analysisDemographicHeaders}
          />
          <VisibleDemographicsSelection
            {...visibleDemographicsData}
            analysisDemographicHeaders={analysisDemographicHeaders}
          />
          {isRedactionFeatureActive && (
            <VisibleRedactionVersion
              {...visibleRedactionVersionData}
              analysisDemographicHeaders={analysisDemographicHeaders}
            />
          )}

          <>
            <StyledAllowPinLabel>
              <Text resource="sharing.permissionForm.allowPin.label" />
            </StyledAllowPinLabel>
            <StyledCheckboxContainer>
              <CheckboxField
                label={<Text resource="sharing.permissionForm.allowPin.checkbox.label" />}
                checked={allowPin}
                onChange={handleAllowPin}
                customStyles={{ labelFontSize: "15px" }}
              />
            </StyledCheckboxContainer>
          </>

          <StyledDashboardSettingsLabel>
            <Text resource="sharing.permissionForm.settings.label" />
          </StyledDashboardSettingsLabel>

          <CheckboxField
            checked={dashboardAccess === SharingDashboardAccess.Shared}
            label={<Text resource="sharing.permissionForm.dashboardAccessOption.dashboardAccess" />}
            onChange={() => handleSelectDashboardAccess()}
            customStyles={checkboxFieldStyle}
          />

          <CheckboxField
            checked={topicExplorerAccess === SharingTopicExplorerAccess.Shared}
            label={
              <Text resource="sharing.permissionForm.dashboardAccessOption.topicExplorerAccess" />
            }
            onChange={() => handleSelectTopicExplorerAccess()}
            customStyles={checkboxFieldStyle}
          />

          <CheckboxField
            checked={commentExplorerAccess === SharingCommentExplorerAccess.Shared}
            label={
              <Text resource="sharing.permissionForm.dashboardAccessOption.commentExplorerAccess" />
            }
            onChange={() => handleSelectCommentExplorerAccess()}
            customStyles={checkboxFieldStyle}
          />

          <CheckboxField
            checked={allowExportData}
            label={<Text resource="sharing.permissionForm.allowExportData" />}
            onChange={handleToggleExportData}
            customStyles={{ marginLeft: "18px", labelFontSize: "15px" }}
          />
        </>
      )}
      {editSharing && (
        <StyledActionsContainer>
          {(visibleAnalysisData.showCustomConditionError ||
            visibleRedactionVersionData.showRedactionCustomConditionError) && (
            <StyledErrorContainer>
              <Icon type={IconType.info} color={Color.red50} size={12} />
              <span>
                <Text resource="sharing.permissionForm.customCondition.error" />
              </span>
            </StyledErrorContainer>
          )}
          <StyledActionButtonsContainer>
            <Button variant={ButtonVariant.light} size={ButtonSize.md} onClick={closeEditSharing}>
              <Text resource="button.cancel" />
            </Button>
            <Button
              variant={ButtonVariant.outline}
              size={ButtonSize.md}
              onClick={navigateToPreview}
              disabled={usersSharedWithCount === 0 && showEditAddUsers}
            >
              <Text resource="button.preview" />
            </Button>
            <Button
              size={ButtonSize.md}
              onClick={applyEditChanges}
              disabled={usersSharedWithCount === 0 && showEditAddUsers}
              loading={isLoading}
            >
              <Text resource="button.applyChanges" />
            </Button>
          </StyledActionButtonsContainer>
        </StyledActionsContainer>
      )}
    </div>
  );
};

const StyledDashboardSettingsLabel = styled.div`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 20px;
`;

const StyledActionButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const StyledUsersSharedWithBlock = styled.div`
  display: flex;
  min-height: 40px;
  align-items: center;
  span {
    font-weight: bold;
  }
`;

const StyledButtonGroup = styled.div`
  margin-left: 24px;
  display: flex;
  gap: 12px;
`;

const StyledErrorContainer = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    color: ${Color.red50};
    font-size: 15px;
  }
`;

const StyledActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledAllowPinLabel = styled.div`
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 6px;
`;

const StyledCheckboxContainer = styled.div`
  background-color: ${Color.neutral10};
  border-radius: 5px;
  padding: 12px 24px;
`;
