import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "store";

import { Modal } from "components/_modals/Modal";
import { TextInput } from "components/_inputs/TextInput";
import { CloseButton } from "components/_buttons/CloseButton";
import { CheckboxField } from "components/_inputs/CheckboxField";
import { Button } from "components/_buttons/Button";
import { RedactionRuleCard } from "components/_modals/RedactionVersionModal/RuleCard";
import { Icon, IconType } from "components/_icons/Icon";
import { Text } from "components/Text";
import { LoadingDotsScreen } from "components/_screens/LoadingDotsScreen";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { RedactionDisplayMethod, RedactionRuleType } from "@explorance/mly-types";

import {
  addRedactionRule,
  clearState,
  setModalOpen,
  setRedactionVersionName,
  toggleManualRedactionEnabled,
} from "store/redaction/redactionModalSlice";
import {
  fetchAnalysisRedactionSets,
  fetchAnalysisRedactionVersions,
  fetchRedactionSets,
  fetchRedactionVersion,
  fetchRedactionVersionsLists,
  handleCreateRedactionVersion,
  handleEditRedactionVersion,
} from "store/redaction/thunks";
import { useResource } from "hooks/useResource";

type Params = {
  analysisId?: number;
};

export const RedactionVersionModal = ({ analysisId }: Params) => {
  const dispatch = useAppDispatch();
  const redactionModalState = useAppSelector((state) => state.redactionModal);

  const addRuleButtonRef = useRef<HTMLDivElement>(null);
  const { getResource } = useResource();

  const DEFAULT_REDACTION_RULE = {
    ruleType: RedactionRuleType.Alert,
    redactionSetId: null,
    displayMethod: RedactionDisplayMethod.Total,
    replacementValue: getResource("comments.redactModal.redactedComment.redacted"),
    alertRange: { min: 90, max: 100 },
  };

  const handleAddRedactionRule = () => {
    dispatch(addRedactionRule(DEFAULT_REDACTION_RULE));
    addRuleButtonRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    analysisId ? dispatch(fetchAnalysisRedactionSets(analysisId)) : dispatch(fetchRedactionSets());

    if (!redactionModalState.redactionVersionId) {
      dispatch(setRedactionVersionName(getResource("redaction.version.defaultName")));
      dispatch(addRedactionRule(DEFAULT_REDACTION_RULE));
    } else {
      dispatch(fetchRedactionVersion());
    }

    return () => {
      dispatch(clearState());
      analysisId
        ? dispatch(fetchAnalysisRedactionVersions(analysisId))
        : dispatch(fetchRedactionVersionsLists());
    };
  }, []); //eslint-disable-line

  return (
    <Modal
      isOpen
      handleRequestCloseFunc={() => dispatch(setModalOpen(false))}
      closeOnOverlayClick={false}
      width="760px"
      height="auto"
      maxHeight="650px"
      padding="18px 24px"
      margin="1% auto"
    >
      {redactionModalState.loadingRedactionVersion && redactionModalState.redactionVersionId ? (
        <LoadingDotsScreen />
      ) : (
        <StyledModalContent>
          <StyledHeader>
            <Text
              resource={
                redactionModalState.redactionVersionId
                  ? "modal.redaction.assign.title.edit"
                  : "modal.redaction.assign.title"
              }
            />
            <CloseButton
              titleKey="button.close"
              closeHandler={() => dispatch(setModalOpen(false))}
            />
          </StyledHeader>

          <StyledVersionNameContainer>
            <b>
              <Text resource="modal.redaction.assign.versionName" />
            </b>
            <TextInput
              value={redactionModalState.redactionVersionName}
              onChange={(versionName) =>
                dispatch(setRedactionVersionName(versionName.target.value))
              }
              onFocusHandler={(event) => event.target.select()}
            />
          </StyledVersionNameContainer>

          <StyledRedactionRuleContainer>
            <StyledRulesHeader>
              <b>
                <Text resource="modal.redaction.assign.rules.title" />
              </b>
              <Text resource="modal.redaction.assign.rules.description" />
            </StyledRulesHeader>
            <StyledRedactionRuleList>
              {redactionModalState.redactionRules.map((rule, index) => (
                <RedactionRuleCard rule={rule} index={index} key={index} />
              ))}
            </StyledRedactionRuleList>

            <StyledAddItemButton onClick={handleAddRedactionRule} ref={addRuleButtonRef}>
              <Button variant={ButtonVariant.outlineBlue} size={ButtonSize.lg}>
                <Icon type={IconType.plusCircle} size={14} />
                <Text resource="button.addAdditionalItem" />
              </Button>
            </StyledAddItemButton>

            <CheckboxField
              checked={redactionModalState.isManualRedactionEnabled}
              onChange={() => dispatch(toggleManualRedactionEnabled())}
              label={
                <StyledCheckboxLabel>
                  <Text resource="modal.redaction.assign.rules.includeManualRedaction" />
                </StyledCheckboxLabel>
              }
              customStyles={{ labelFontSize: "14px" }}
            />
          </StyledRedactionRuleContainer>

          <StyledButtonContainer>
            <Button
              variant={ButtonVariant.primary}
              onClick={() =>
                redactionModalState.redactionVersionId
                  ? dispatch(handleEditRedactionVersion()).unwrap()
                  : dispatch(handleCreateRedactionVersion(analysisId)).unwrap()
              }
              size={ButtonSize.ml}
            >
              <Text
                resource={
                  redactionModalState.redactionVersionId
                    ? "button.save"
                    : "modal.redaction.assign.createVersion"
                }
              />
            </Button>
            <Button
              variant={ButtonVariant.light}
              style={{ width: "96px" }}
              onClick={() => dispatch(setModalOpen(false))}
            >
              <Text resource="button.cancel" />
            </Button>
          </StyledButtonContainer>
        </StyledModalContent>
      )}
    </Modal>
  );
};

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${Color.gray50};
`;

const StyledHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 12px;
  margin-top: 18px;
`;

const StyledVersionNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
  margin-top: 30px;
`;

const StyledRedactionRuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledRulesHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  font-size: 14px;
  gap: 2px;
`;

const StyledRedactionRuleList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledCheckboxLabel = styled.div`
  font-weight: bold;
  color: ${Color.gray50};
`;

const StyledAddItemButton = styled.div`
  button {
    width: 100%;
    border-radius: 5px;
    outline-style: dashed;
    border: none;
    outline-width: thin;
    gap: 2px;

    &:hover,
    &:focus,
    &:active {
      border: none !important;
    }
  }
`;
