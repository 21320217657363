import React, { useState, useEffect, CSSProperties } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "store";

import { useResource } from "hooks/useResource";
import {
  addUsersToExistingSharingDraft,
  getUsersInSharingDraft,
  removeUsersFromExistingSharingDraft,
} from "services/analysis/sharing";

import { setModalSearchTerm } from "store/search/searchbarSlice";

import { Header } from "./Header";
import { FirstNameCell } from "./FlexTableCells/FirstNameCell";
import { EmailCell } from "./FlexTableCells/EmailCell";
import { LastNameCell } from "./FlexTableCells/LastNameCell";
import { OverrideSettingsCell } from "./FlexTableCells/OverrideSettingsCell";
import { FlexTable, FlexTableSorting } from "components/FlexTable";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { EmptyState } from "components/EmptyState";
import { Modal, StyledModalTitle } from "components/_modals/Modal";
import { Text } from "components/Text";
import {
  clearUserSelectionState,
  setSelectedUserDemographicFilters,
} from "store/sharing/userSelectionSlice";

import {
  ShareStatus,
  ShareableUsersSortingParameter,
  SortingOrder,
  SharingMethod,
} from "@explorance/mly-types";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { FlexTableType } from "ts/enums/flexTable";
import { Color } from "ts/enums/color";
import { EmptyStateType } from "ts/enums/emptyStateType";
import { EditGroupModalView } from "ts/enums/editGroupModalView";
import { setIsModalOpen, setSelectedUsersCount } from "store/sharing/editSharingBlockSlice";
import {
  clearUserSelectionPopupState,
  setAvailableUsers,
  setCurrentPage,
  setIsUserDemographicFiltersReset,
  setSortOrder,
  setSortingColumn,
} from "store/sharing/userSelectionPopupSlice";
import { fetchSharingUsersData } from "store/sharing/thunks";

const SHARE_TO_USERS_HEADERS = [
  { columnNameKey: "firstName", sortingParameter: ShareableUsersSortingParameter.FirstName },
  { columnNameKey: "lastName", sortingParameter: ShareableUsersSortingParameter.LastName },
  { columnNameKey: "email", sortingParameter: ShareableUsersSortingParameter.Email },
  { columnNameKey: "role", sortingParameter: ShareableUsersSortingParameter.RoleType },
];

type Props = {
  sharingId: number;
};

export const UserSelectionPopup = ({ sharingId }: Props) => {
  const searchTerm = useAppSelector((state) => state.search.modalSearchTerm);
  const editSharingBlockState = useAppSelector((state) => state.editSharingBlock);
  const userSelectionPopupState = useAppSelector((state) => state.userSelectionPopup);
  const dispatch = useAppDispatch();

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.matchMedia("(max-width: 1250px)").matches
  );

  const { getResource } = useResource();
  const analysisId = parseInt(useParams<{ analysisId: string }>().analysisId);

  const toggleSelectUser = async (selectedUserId: number) => {
    //find the user in the available users array and toggle the isSelected property for that user and add user to selected users array
    const updatedAvailableUsers = cloneDeep(userSelectionPopupState.availableUsers);

    const userIndex = updatedAvailableUsers.findIndex((u) => u.id === selectedUserId);
    const user = updatedAvailableUsers[userIndex];
    user.isSelected = !user.isSelected;

    dispatch(setAvailableUsers(updatedAvailableUsers));
    const action = user.isSelected
      ? addUsersToExistingSharingDraft
      : removeUsersFromExistingSharingDraft;
    await action({ sharingId, userIds: [user.id], sharingMethod: SharingMethod.Group }).then(
      async () => {
        await getUsersInSharingDraft({
          sharingId,
        }).then(({ data }) => dispatch(setSelectedUsersCount(data.totalCount)));
      }
    );
  };

  const toggleSelectAllUsers = async () => {
    //put isSelected to true for all users in available users array and add all users to selected users array
    // if every user is already selected, then deselect all users
    const updatedAvailableUsers = cloneDeep(userSelectionPopupState.availableUsers);

    const allUsersSelected = updatedAvailableUsers.every((u) => u.isSelected);
    if (allUsersSelected) {
      updatedAvailableUsers.forEach((u) => {
        u.isSelected = false;
      });
    } else {
      updatedAvailableUsers.forEach((u) => {
        u.isSelected = true;
      });
    }
    dispatch(setAvailableUsers(updatedAvailableUsers));

    const action = allUsersSelected
      ? removeUsersFromExistingSharingDraft
      : addUsersToExistingSharingDraft;
    const userIds = updatedAvailableUsers.map((u) => u.id);
    await action({ sharingId, userIds, sharingMethod: SharingMethod.Group }).then(async () => {
      await getUsersInSharingDraft({
        sharingId,
      }).then(({ data }) => dispatch(setSelectedUsersCount(data.totalCount)));
    });
  };

  const updateSorting = (updatedSorting: FlexTableSorting) => {
    const { columnIndex, order } = updatedSorting;
    dispatch(setSortingColumn(SHARE_TO_USERS_HEADERS[columnIndex].sortingParameter));
    dispatch(setSortOrder(order));
    dispatch(fetchSharingUsersData({ analysisId, sharingId }));
  };

  const updatePage = (updatedPage: number) => {
    dispatch(setCurrentPage(updatedPage));
    dispatch(fetchSharingUsersData({ analysisId, sharingId }));
  };

  const clearAllFilters = () => {
    dispatch(setSortingColumn(ShareableUsersSortingParameter.FirstName));
    dispatch(setSortOrder(SortingOrder.ASC));
    dispatch(setModalSearchTerm(""));
    dispatch(setIsUserDemographicFiltersReset(true));
    dispatch(setSelectedUserDemographicFilters([]));
    dispatch(fetchSharingUsersData({ analysisId, sharingId }));
  };

  const deleteUserFromDraft = async (userId: number) => {
    await removeUsersFromExistingSharingDraft({
      sharingId,
      sharingMethod: SharingMethod.Group,
      userIds: [userId],
    })
      .then(() => {
        dispatch(fetchSharingUsersData({ analysisId, sharingId }));
        dispatch(setSelectedUsersCount(editSharingBlockState.selectedUsersCount - 1));
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    dispatch(fetchSharingUsersData({ analysisId, sharingId }));
    const handleResize = () => {
      const documentHeight = document.documentElement.offsetHeight;
      const documentWidth = document.documentElement.offsetWidth;
      setIsSmallScreen(documentHeight <= 800 || documentWidth <= 1250);
    };

    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    return () => {
      dispatch(clearUserSelectionPopupState());
      dispatch(clearUserSelectionState());
      window.removeEventListener("resize", handleResize);
    };
  }, []); // eslint-disable-line

  return (
    <Modal
      isOpen={editSharingBlockState.isModalOpen}
      styles={{
        content: {
          position: "relative" as CSSProperties["position"],
          boxShadow: "0px 4px 16px #B6BACB29",
          margin: isSmallScreen ? "50px auto" : "100px auto",
          padding: "22px",
          width: isSmallScreen ? "950px" : "1200px",
          height: isSmallScreen ? "625px" : "720px",
          border: `1px solid ${Color.neutral30}`,
          borderRadius: "5px",
          backgroundColor: Color.white,
          inset: "0px",
          overflow: "hidden",
        },
      }}
    >
      <StyledTopSection>
        <StyledModalTitle>
          <Text resource="modal.editGroup.addUsers" />
        </StyledModalTitle>
      </StyledTopSection>
      <StyledModalBody isSmallScreen={isSmallScreen}>
        <Header
          pageTitle={getResource("userList.title")}
          searchCountDataType="searchBar.dataType.userList"
          currentPage={userSelectionPopupState.currentPage}
          totalItemCount={userSelectionPopupState.totalUserCount}
          isModal
          currentItemCount={userSelectionPopupState.currentUserCount}
          showUserDemographicFilters={
            editSharingBlockState.editGroupModalView === EditGroupModalView.add
          }
          isUserDemographicFiltersReset={userSelectionPopupState.isUserDemographicFiltersReset}
          updatePage={updatePage}
          refetchList={(demographicFilters) =>
            dispatch(fetchSharingUsersData({ analysisId, sharingId, demographicFilters }))
          }
          onSearch={() => dispatch(fetchSharingUsersData({ analysisId, sharingId }))}
        />
        {userSelectionPopupState.availableUsers.length === 0 && searchTerm.length > 0 ? (
          <EmptyState
            type={EmptyStateType.noSearchResults}
            customStyles={{ marginTop: "85px", marginBottom: "85px" }}
            handleClickCaptionLink={clearAllFilters}
          />
        ) : userSelectionPopupState.availableUsers.length === 0 &&
          searchTerm.length === 0 &&
          editSharingBlockState.editGroupModalView === EditGroupModalView.edit ? (
          <EmptyState type={EmptyStateType.noUsersInGroup} />
        ) : (
          <FlexTable
            type={FlexTableType.Card}
            data={{
              headers: SHARE_TO_USERS_HEADERS.map((header) =>
                getResource(`sharing.table.${header.columnNameKey}`)
              ),
              rows: userSelectionPopupState.availableUsers.map((user) => ({
                isSelected:
                  editSharingBlockState.editGroupModalView === EditGroupModalView.add &&
                  user.isSelected,
                contentId: user.id,
                data: [
                  <FirstNameCell key={`${user.id}-firstname}`} user={user} />,
                  <LastNameCell key={`${user.id}-lastname}`} user={user} />,
                  <EmailCell key={`${user.id}-email}`} user={user} />,
                  <StyledRoleCell key={user.id}>
                    <StyledRole>
                      <Text resource={`roleType.${user.roleType}`} />
                    </StyledRole>
                    {editSharingBlockState.editGroupModalView === EditGroupModalView.edit ||
                    user.shareStatus === ShareStatus.None ? (
                      <div />
                    ) : user.isSelected ? (
                      <OverrideSettingsCell id={user.id} isSmallScreen={isSmallScreen} />
                    ) : (
                      <StyledAlreadySharedPill>
                        <Text resource="sharing.pill.alreadyShared" />
                      </StyledAlreadySharedPill>
                    )}
                    {editSharingBlockState.editGroupModalView === EditGroupModalView.add ? (
                      <Button
                        variant={ButtonVariant.secondary}
                        size={ButtonSize.sm}
                        style={{ justifySelf: "right" }}
                        onClick={() => toggleSelectUser(user.id)}
                      >
                        <Icon
                          type={user.isSelected ? IconType.xCircle : IconType.plusCircle}
                          size={14}
                          style={{ marginRight: 5 }}
                        />
                        <Text
                          resource={{
                            key: user.isSelected ? "button.deselectUser" : "button.selectUser",
                          }}
                        />
                      </Button>
                    ) : (
                      <Button
                        variant={ButtonVariant.light}
                        size={ButtonSize.sm}
                        style={{ color: Color.red50 }}
                        onClick={() => deleteUserFromDraft(user.id)}
                      >
                        <Icon type={IconType.xCircle} size={14} style={{ marginRight: 5 }} />
                        <Text resource="button.unshare" />
                      </Button>
                    )}
                  </StyledRoleCell>,
                ],
              })),
              columnWidths: ["17.5%", "17.5%", "20%", "43%"],
            }}
            isSelectable={editSharingBlockState.editGroupModalView === EditGroupModalView.add}
            initialSorting={{
              columnIndex: SHARE_TO_USERS_HEADERS.findIndex(
                (h) => h.sortingParameter === userSelectionPopupState.sortingColumn
              ),
              order: userSelectionPopupState.sortOrder,
            }}
            onSelectionChange={
              editSharingBlockState.editGroupModalView === EditGroupModalView.add
                ? toggleSelectUser
                : undefined
            }
            handleSelectAll={toggleSelectAllUsers}
            onSortingChange={updateSorting}
            maxHeight={isSmallScreen ? "320px" : "400px"}
          />
        )}
      </StyledModalBody>

      <StyledButtonGroup marginTop="20px">
        <Button
          variant={ButtonVariant.outlineBlue}
          size={ButtonSize.md}
          onClick={() => dispatch(setIsModalOpen(false))}
        >
          <Text resource="button.done" />
        </Button>
      </StyledButtonGroup>
    </Modal>
  );
};

const StyledRole = styled.div`
  width: 100px;
  justify-self: start;
`;

const StyledAlreadySharedPill = styled.div`
  display: inline;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background-color: ${Color.indigo20};
  font-size: 14px;
  text-align: center;
  justify-self: center;
`;

const StyledRoleCell = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: 0.5fr 1fr 1fr;
  gap: 10px;
  align-items: center;
`;

const StyledButtonGroup = styled.div<{ marginTop: string }>`
  margin-top: ${({ marginTop }) => marginTop};
  display: flex;
  width: 100%;
  gap: 12px;
  align-items: end;
  justify-content: flex-end;
`;

const StyledModalBody = styled.div<{ isSmallScreen: boolean }>`
  border: 1px solid ${Color.neutral30};
  border-radius: 5px;
  padding: 10px;
  min-height: ${({ isSmallScreen }) => (isSmallScreen ? "490px" : "568px")};
`;

const StyledTopSection = styled.div`
  display: flex;
  margin-bottom: 18px;
`;
