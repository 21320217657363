import { CategorizationType, LanguageCode } from "@explorance/mly-types";
import { getLocalTime } from "utils/getLocalTime";

export type ModelLicense = {
  createdDate: Date;
  deprecatesOn: Date | null;
  description: string;
  family: CategorizationType;
  env: string;
  isActivated: boolean;
  isPowerset: boolean;
  languages: Array<LanguageCode>;
  lastUpdated: Date;
  modelId: number;
  name: string;
  version: string;
};

export type ModelStatistics = {
  modelFamily: CategorizationType;
  isPowerset: boolean;
  languages: Array<LanguageCode>;
  analyzedCount: string;
  name: string;
};

export type LicensePeriod = {
  id: number;
  commentLimit: number;
  startDate: Date;
  endDate: Date;
  isActive: boolean;
  userLimit: number;
  userLimitAdmin: number;
  userLimitAnalyst: number;
  userLimitGuest: number;
  notificationQuota: number;
  notificationEmails: string[];
  accountManagerEmails: string[];
  notificationEmailsCc?: string[];
  notificationEmailsCcc?: string[];
  createdDate: Date;
};

export const getLicensePeriodFromData = (data: any): LicensePeriod => ({
  ...data,
  startDate: getLocalTime(data.startDate).toISOString(),
  endDate: getLocalTime(data.endDate).toISOString(),
  createdDate: getLocalTime(data.createdDate).toISOString(),
  id: data.licencePeriodId,
});
