import React from "react";
import styled from "styled-components";

import failUpload from "assets/images/import-error.png";

import { Text } from "components/Text";

import { Color } from "ts/enums/color";
import { Icon, IconType } from "components/_icons/Icon";
import { useAppSelector } from "store";
import { TaskStatusDetails } from "@explorance/mly-types";

export const UploadFailedScreen = () => {
  const { importModalAnalysisName, error } = useAppSelector((state) => state.dataSource);

  return (
    <StyledImportFailedContainer>
      <StyledStatusFailedHeader>
        <Icon type={IconType.info} color={Color.white} size={14} />
        <Text resource="modal.import.failed.header" />
      </StyledStatusFailedHeader>
      <StyledUploadFailedBody>
        <StyledErrorImageContainer>
          <img src={failUpload} alt="Warning" width={160} />
        </StyledErrorImageContainer>
        <StyledErrorDetails>
          <StyledDetailsTitle>
            <Text resource="modal.import.failed.details.header" />
          </StyledDetailsTitle>
          <StyledDetailsBody>
            <StyledBodyHeader>
              <Text resource="modal.import.failed.details.body.header" />
            </StyledBodyHeader>
            <StyledErrorDescription>
              {"• "}{" "}
              <Text
                resource={{
                  key: Object.keys(TaskStatusDetails).includes(error?.type)
                    ? `modal.import.error.[${error?.type}]`
                    : "modal.import.error.defaultErrorMessage",
                  args: [
                    importModalAnalysisName,
                    error?.details?.ErrorHeaderValue ||
                      error?.details?.ErrorColumn ||
                      error?.details?.MaxAnalysisRows ||
                      error?.details?.MaxColumnNumber,
                    error?.details?.MaxHeaderLength,
                  ],
                }}
              />
            </StyledErrorDescription>
          </StyledDetailsBody>
        </StyledErrorDetails>
      </StyledUploadFailedBody>
    </StyledImportFailedContainer>
  );
};

const StyledImportFailedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
`;

const StyledStatusFailedHeader = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 16px;
  align-items: center;
  font-size: 16px;
  border-radius: 5px;
  gap: 8px;
  background-color: ${Color.red50};
  color: ${Color.white};
`;

const StyledUploadFailedBody = styled.div`
  display: flex;
  margin-top: 50px;
  padding: 30px 0px;
  width: 660px;
  height: 250px;
`;

const StyledErrorImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
`;

const StyledErrorDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: flex-end;
  flex: 1;
`;

const StyledDetailsTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  width: 340px;
`;

const StyledDetailsBody = styled.div`
  width: 340px;
  padding: 12px;
  background-color: ${Color.red10};
  border: 1px solid ${Color.red50};
  border-radius: 3px;
`;

const StyledBodyHeader = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${Color.red50};
`;

const StyledErrorDescription = styled.div`
  font-size: 14px;
  margin-top: 10px;
  color: ${Color.red50};
  word-wrap: break-word;
`;
