import { ManualRedactionPermission, RoleType } from "@explorance/mly-types";
import { ResultsActionType } from "./actions";
import { AnalysisContextState } from "./state";
import { DEFAULT_ALERT_RANGE } from "assets/constants/defaultAlertRange";

// state reducer
export const resultsReducer = (state: AnalysisContextState, action): AnalysisContextState => {
  switch (action.type) {
    case ResultsActionType.SET_SELECTED_MODEL_ID:
      return { ...state, selectedModelId: action.payload };
    case ResultsActionType.SET_LAST_SELECTED_MODEL_ID:
      return { ...state, lastSelectedModelId: action.payload };
    case ResultsActionType.SET_ANALYSIS_ERROR:
      return { ...state, analysisError: action.payload };
    case ResultsActionType.SET_ANALYSIS_MODELS:
      return { ...state, analysisModels: action.payload };
    case ResultsActionType.SET_ANALYSIS_DETAILS:
      return { ...state, analysisDetails: action.payload };
    case ResultsActionType.SET_COMMENTER_DETAILS:
      return { ...state, commenterDetails: action.payload };
    case ResultsActionType.SET_EMPTY_COMMENTS_DETAILS:
      return { ...state, emptyCommentsDetails: action.payload };
    case ResultsActionType.SET_LOADING_AVAILABLE_DEMOGRAPHIC_FILTERS:
      return { ...state, loadingAvailableDemographicFilters: action.payload };
    case ResultsActionType.SET_LOADING_COMMENT_STATISTICS:
      return { ...state, loadingCommentStatistics: action.payload };
    case ResultsActionType.SET_LOADING_PREVIEW_USERS:
      return { ...state, loadingPreviewUsers: action.payload };
    case ResultsActionType.SET_AVAILABLE_DEMOGRAPHIC_FILTERS:
      return { ...state, availableDemographicFilters: action.payload };
    case ResultsActionType.SET_SELECTED_DEMOGRAPHIC_FILTERS:
      return { ...state, selectedDemographicFilters: action.payload };
    case ResultsActionType.SET_SELECTED_TOPIC_FILTER_IDS:
      return { ...state, selectedTopicNodes: action.payload };
    case ResultsActionType.SET_LAST_SELECTED_TOPIC_FILTER_IDS:
      return { ...state, lastSelectedTopicNodes: action.payload };
    case ResultsActionType.SET_THRESHOLD:
      return { ...state, threshold: action.payload };
    case ResultsActionType.SET_ALERT_THRESHOLD:
      return { ...state, alertThreshold: action.payload };
    case ResultsActionType.SET_WIDGETS_API_DATA:
      return { ...state, widgetsApiData: action.payload };
    case ResultsActionType.SET_COMMENT_STATISTICS:
      return { ...state, commentStatistics: action.payload };
    case ResultsActionType.SET_LOADING_ANALYSIS_DETAILS:
      return { ...state, loadingAnalysisDetails: action.payload };
    case ResultsActionType.SET_LOADING_ANALYSIS_MODELS:
      return { ...state, loadingAnalysisModels: action.payload };
    case ResultsActionType.RESET_ALL_FILTERS:
      return {
        ...state,
        selectedTopicNodes: [],
        selectedDemographicFilters: [],
        threshold: null,
        selectedColumnFilters: [],
        selectedCategories: [],
        selectedAlertRange: DEFAULT_ALERT_RANGE,
        selectedModelId:
          state.analysisDetails.selectedModel?.customModelId || state.selectedModelId,
      };
    case ResultsActionType.RESET_PREVIEW_USER:
      return {
        ...state,
        previewUser: {
          firstname: null,
          lastname: null,
          id: null,
          allowExport: false,
          allowPin: false,
          overviewAccess: null,
          topicExplorerAccess: null,
          commentExplorerAccess: null,
          dashboardAccess: null,
          manualRedactionPermission: ManualRedactionPermission.Denied,
          roleType: RoleType.Viewer,
        },
      };
    case ResultsActionType.SHOW_WHITE_BACKGROUND:
      return { ...state, showWhiteBackground: action.payload };
    case ResultsActionType.SET_SELECTED_COLUMN_FILTERS:
      return { ...state, selectedColumnFilters: action.payload };
    case ResultsActionType.SET_SELECTED_ALERT_RANGE:
      return { ...state, selectedAlertRange: action.payload };
    case ResultsActionType.SET_PREVIEW_USER:
      return { ...state, previewUser: action.payload };
    case ResultsActionType.SET_PREVIEW_USERS:
      return { ...state, previewUsers: action.payload };
    case ResultsActionType.SET_LAST_WIDGETS_PUSH:
      return {
        ...state,
        widgetsApiData: {
          ...state.widgetsApiData,
          ...action.payload,
        },
      };
    case ResultsActionType.SET_SELECTED_TRANSLATION_LANGUAGE:
      return { ...state, selectedTranslationLanguage: action.payload };
    case ResultsActionType.SET_SELECTED_CATEGORIES:
      return { ...state, selectedCategories: action.payload };
    case ResultsActionType.SET_AVAILABLE_CATEGORIES:
      return { ...state, availableCategories: action.payload };
    case ResultsActionType.SET_COMMENTS_SEARCH_TYPE:
      return { ...state, commentsSearchType: action.payload };
  }
};
