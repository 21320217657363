import React, { useEffect, useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { round } from "lodash";

import { BreakdownTypes, WidgetStat, WidgetType, WidgetWidth } from "@explorance/mly-types";
import { Color } from "ts/enums/color";
import { WidgetFilter } from "ts/widget";

import { sentimentStyles } from "assets/constants/sentimentStyles";
import { useResource } from "hooks/useResource";
import { getTranslatedFullPath } from "./helpers";

import { SentimentIcon } from "components/_icons/SentimentIcon";
import { Icon, IconType } from "components/_icons/Icon";
import { RecommendationIcon } from "components/_icons/RecommendationIcon";
import { TextTruncator } from "components/TextTruncator";
import { Text } from "components/Text";
import { DisplayedStats } from "./DisplayedStats";
import { Tooltip } from "components/Tooltip";
import { TopicPathLabel } from "components/TopicFullPathLabel";

type Props = {
  data: Record<string, any>;
  widgetWidth: WidgetWidth;
  widgetType: WidgetType;
  breakdownType: BreakdownTypes;
  selectedStats: WidgetStat[];
  isVirtual: boolean;
  widgetFilter: WidgetFilter;
  canAccessComments: boolean;
  onClickMetric: () => void;
  onClickL1Statistic?: () => void;
  onClickCommentsIcon?: () => void;
};

export const L1 = ({
  data,
  isVirtual,
  widgetType,
  widgetWidth,
  widgetFilter,
  selectedStats,
  breakdownType,
  canAccessComments,
  onClickMetric,
  onClickL1Statistic,
  onClickCommentsIcon,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isMetricNameHovered, setIsMetricNameHovered] = useState<boolean>(false);
  const [detailStatsWidth, setDetailStatsWidth] = useState<number>(0);

  const memoizedDetailStatsWidth = useMemo(() => detailStatsWidth, [detailStatsWidth]);
  const { getResource } = useResource();

  const detailStatsRef = useRef<HTMLDivElement>(null);
  const resizeTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  // Main metric (left part of the widget stat)
  const isMetricClickable = !data.isOthers && !!onClickMetric;
  const onMetricMouseOver = !data.isOthers ? () => setIsMetricNameHovered(true) : undefined;

  // Main statistic (bottom-right value of L1)
  const isPercentageMainStatistic = [WidgetType.MostPositive, WidgetType.WhatToImprove].includes(
    widgetType
  );
  const isMainStatisticValueClickable =
    !data.isOthers && !isPercentageMainStatistic && !!onClickL1Statistic;

  const onClickMainStatisticValue =
    (!data.isOthers && !isPercentageMainStatistic && onClickL1Statistic) || undefined;

  const getMetricIconType = (): IconType => {
    switch (breakdownType) {
      case BreakdownTypes.Topics:
        return IconType.hierarchySolid;
      case BreakdownTypes.Demographics:
        return IconType.group;
      default:
        return null;
    }
  };

  const getMainStatisticIcon = () => {
    switch (widgetType) {
      case WidgetType.MostPopular:
      case WidgetType.AllSentiments:
        return <Icon type={IconType.comment} color={Color.gray50} size={16} />;
      case WidgetType.MostPositive:
      case WidgetType.WhatToImprove:
        return <SentimentIcon type={data.dominantSentiment.sentimentType} />;
      case WidgetType.PrioritizedRecommendations:
        return RecommendationIcon({ type: data.recommendationType });
      case WidgetType.CategorizedRecommendations:
      case WidgetType.AllRecommendations:
        if (breakdownType === BreakdownTypes.RecommendationType) {
          return RecommendationIcon({ type: data.name });
        }
        return <Icon type={IconType.recommendation} color={Color.gray50} size={16} />;
      case WidgetType.MostAlerts:
        return <Icon type={IconType.warning} color={Color.red50} size={16} />;
      default:
        return null;
    }
  };

  const getMainStatisticValue = () => {
    switch (widgetType) {
      case WidgetType.MostPopular:
      case WidgetType.AllSentiments:
        return data.commentCount;
      case WidgetType.MostPositive:
      case WidgetType.WhatToImprove: {
        const sentimentColor =
          sentimentStyles[data.dominantSentiment.sentimentType]?.color || Color.gray50;
        return (
          <span style={{ color: sentimentColor }}>
            {round(data.dominantSentiment.percentage, 2)}%
          </span>
        );
      }
      case WidgetType.PrioritizedRecommendations:
      case WidgetType.CategorizedRecommendations:
      case WidgetType.AllRecommendations:
        return data.recommendationCount;
      case WidgetType.MostAlerts:
        return <span style={{ color: Color.red50 }}>{data.alertCount}</span>;
      default:
        return null;
    }
  };

  const getMainStatisticName = (isOthers: boolean) => {
    if (isOthers) {
      return <Text resource="widget.others" />;
    }
    switch (breakdownType) {
      case BreakdownTypes.Topics: {
        const translatedFullPath = getTranslatedFullPath(data?.fullPath, isVirtual, getResource);
        return <TopicPathLabel topics={translatedFullPath} widgetWidth={widgetWidth} />;
      }
      case BreakdownTypes.RecommendationType:
        return <Text resource={`statTab.${data.name}`} />;
      case BreakdownTypes.Demographics:
        return data.value;
      case BreakdownTypes.SentimentType:
        return <Text resource={`statTab.${data.name}`} />;
      default:
        return null;
    }
  };

  const getDisplayedMetricName = () => {
    switch (breakdownType) {
      case BreakdownTypes.Topics:
        return <Text resource="widget.metric.topic" />;
      case BreakdownTypes.Demographics:
        return data.name;
      case BreakdownTypes.RecommendationType:
        return <Text resource="breakdownBy.recommendationType" />;
      case BreakdownTypes.SentimentType:
        return <Text resource="breakdownBy.sentimentType" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (detailStatsRef.current) {
      setDetailStatsWidth(detailStatsRef.current.offsetWidth);
    }
  }, [widgetType]);

  useEffect(() => {
    const updateWidth = () => {
      if (detailStatsRef.current) {
        setDetailStatsWidth(detailStatsRef.current.offsetWidth);
      }
    };

    const handleResize = () => {
      clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(updateWidth, 250);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(resizeTimer.current);
    };
  }, []);

  return (
    <>
      <StyledTopSection>
        <StyledMetricName
          widgetWidth={widgetWidth}
          isMetricNameHovered={isMetricNameHovered}
          onMouseOver={onMetricMouseOver}
          onMouseOut={() => setIsMetricNameHovered(false)}
          onClick={onClickMetric}
          detailStatsRefWidth={memoizedDetailStatsWidth || 0}
          isClickable={isMetricClickable}
        >
          <Icon
            type={getMetricIconType()}
            color={Color.gray50}
            size={12}
            style={{ marginRight: 6 }}
          />
          <TextTruncator id={data.id} value={getDisplayedMetricName()} />
        </StyledMetricName>
        <DisplayedStats
          selectedStats={selectedStats}
          data={data}
          widgetType={widgetType}
          showTopicSummaryButton={breakdownType === BreakdownTypes.Topics && !data.isOthers}
          onClickComments={data.isOthers || !canAccessComments ? undefined : onClickCommentsIcon}
          widgetFilter={widgetFilter}
          isVirtual={isVirtual}
        />
      </StyledTopSection>
      <StyledBottomSection>
        <StyledMainStatisticName
          onMouseOver={onMetricMouseOver}
          onMouseOut={() => setIsMetricNameHovered(false)}
          isHovered={isMetricNameHovered}
          onClick={onClickMetric}
          isClickable={isMetricClickable}
        >
          <TextTruncator id={data.id} value={getMainStatisticName(data.isOthers)} />
        </StyledMainStatisticName>
        <StyledMainStatisticValue
          isClickable={isMainStatisticValueClickable}
          onClick={onClickMainStatisticValue}
        >
          <div
            data-tooltip-id={data.recommendationType}
            onMouseOver={() =>
              [WidgetType.PrioritizedRecommendations].includes(widgetType) && setShowTooltip(true)
            }
            onMouseLeave={() => setShowTooltip(false)}
          >
            {getMainStatisticIcon()}
            {showTooltip && (
              <Tooltip
                tooltipId={data.recommendationType}
                content={<Text resource={`statTab.${data.recommendationType}`} />}
              />
            )}
          </div>
          {getMainStatisticValue()}
        </StyledMainStatisticValue>
      </StyledBottomSection>
    </>
  );
};

const StyledTopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const StyledMetricName = styled.div<{
  widgetWidth: WidgetWidth;
  isMetricNameHovered: boolean;
  detailStatsRefWidth: number;
  isClickable: boolean;
}>`
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  padding-bottom: 9px;
  min-width: 0px;
  ${({ isMetricNameHovered, isClickable }) =>
    isMetricNameHovered &&
    isClickable &&
    css`
      text-decoration: underline;
    `}
`;

const StyledBottomSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMainStatisticName = styled.div<{ isHovered: boolean; isClickable: boolean }>`
  margin-top: -1px; // keep to avoid cursor flickering when hovering over the topic label
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 0px;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  ${({ isHovered, isClickable }) =>
    isHovered &&
    isClickable &&
    css`
      text-decoration: underline;
    `};
`;

const StyledMainStatisticValue = styled.div<{ isClickable: boolean }>`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 22px;
  font-weight: bold;
  padding-left: 3px;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
`;
