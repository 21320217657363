import {
  DemographicFilterMethod,
  ShareStatus,
  SharingMethod,
  SharingOverviewAccess,
} from "@explorance/mly-types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSharedUsersFromApiData,
  getVisibleCommentDemsForFetch,
  getVisibleCommentDemsFromData,
  getVisibleDemFiltersFromData,
} from "utils/sharing";
import { UserDemographic } from "context/UserProfileContext";
import { useResource } from "hooks/useResource";
import { getAnalysisRedactionVersions, getDemographicsByAnalysisId } from "services/analysis";
import {
  GetRecipientsUsersParams,
  addUsersToNewSharingDraft,
  getRecipientsGroups,
  getRecipientsUsers,
  getShareableUsers,
  getSharingDraft,
  getSharingDraftBySharingId,
  getSharingDraftUsers,
  getUsersInSharingDraft,
  removeUsersFromNewSharingDraft,
  updateSharingDraft,
  updateSharingDraftBySharingId,
} from "services/analysis/sharing";
import { getAllUserDemographics } from "services/users";
import { AppDispatch } from "store";
import { AppState } from "store/appReducer";
import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import { RecipientGroupsApiData, SharingDraft, SharingUsersApiData } from "ts/sharing";
import { typeDataToUserDemographics } from "utils/userDemographics";
import { setSelectedUsers, setSelectedUsersCount } from "./userSelectionSlice";
import { PILLS_LIMITS } from "assets/constants/sharingDraftLimits";
import { SharingDraftPermissions } from "ts/permissions";
import { DefaultWidgets } from "ts/enums/sharing";
import { getSharingMethodFromView } from "utils/getSharingMethodFromSharingView";
import { DEFAULT_LIST_LIMIT } from "assets/constants/listLimits";
import { formatSearchForFetchRequest } from "utils/formatters";
import { formatDemographicsForApi } from "utils/filters";
import { showToastError } from "store/toast/toastSlice";
import {
  setAllDataLoaded,
  setAllowExportData,
  setAllowPin,
  setCommentExplorerAccess,
  setDashboardAccess,
  setManualRedactionPermission,
  setSelectedCustomConditions,
  setSelectedDemographics,
  setSelectedPermissionLevel,
  setSelectedRedactionCustomConditions,
  setSelectedRedactionVersion,
  setSelectedUsersCount as setSelectedUsersCountInSharing,
  setTopicExplorerAccess,
  setVisibleDemographicsFilters,
} from "./editSharingBlockSlice";
import {
  setAvailableUsers,
  setCurrentUserCount,
  setTotalUserCount,
} from "./userSelectionPopupSlice";
import { EditGroupModalView } from "ts/enums/editGroupModalView";
import { RedactionVersion } from "ts/redaction";

export const fetchAllUserDemographics = createAsyncThunk<
  UserDemographic[],
  void,
  { state: AppState }
>("sharing/fetchAllUserDemographics", async (_, { getState }): Promise<UserDemographic[]> => {
  const state = getState();
  const resources = state.resourcesSlice.resources;
  const topicResources = state.resourcesSlice.topicResources;
  const userLanguage = state.auth.currentUser.preferredLanguage;
  const { getResource } = useResource(userLanguage, resources, topicResources);

  const { data } = await getAllUserDemographics();

  const normalizedDemographics = data.userDemographics
    .filter((d) => d.values !== null)
    .map((d) => {
      const typedData = typeDataToUserDemographics(d);
      return {
        ...typedData,
        values: typedData.values.map((val) =>
          val === "sharing.userDemographics.nullValue" ? getResource(val) : val
        ),
      };
    }) as UserDemographic[];

  if (!normalizedDemographics) {
    throw new Error("Failed to fetch user demographics");
  }

  return normalizedDemographics;
});

export const fetchSharedUsersData = createAsyncThunk<
  SharingUsersApiData,
  number,
  { state: AppState }
>("sharing/fetchSharedUsersData", async (analysisId, { getState }) => {
  const state = getState();
  const { currentPage, sortingColumnUsers, sortOrder } = state.sharedWith;

  const fetchParams: GetRecipientsUsersParams = {
    analysisId,
    page: currentPage,
    search: formatSearchForFetchRequest(state.search.searchTerm),
    sort_column: sortingColumnUsers,
    sort_order: sortOrder,
    limit: DEFAULT_LIST_LIMIT,
  };

  const { data } = await getRecipientsUsers(fetchParams);

  if (!data) {
    throw new Error("Failed to fetch shared users data");
  }

  return {
    totalCount: data.totalCount,
    itemCount: data.itemCount,
    users: getSharedUsersFromApiData(data.users),
  };
});

export const fetchSharedGroupsData = createAsyncThunk<
  RecipientGroupsApiData,
  number,
  { state: AppState }
>("sharing/fetchSharedGroupsData", async (analysisId, { getState }) => {
  const state = getState();

  const { data } = await getRecipientsGroups({
    analysisId,
    page: state.sharedWith.currentPage,
    search: formatSearchForFetchRequest(state.search.searchTerm),
    sort_column: state.sharedWith.sortingColumnGroups,
    sort_order: state.sharedWith.sortOrder,
    limit: DEFAULT_LIST_LIMIT,
  });

  if (!data) {
    throw new Error("Failed to fetch shared groups data");
  }

  return {
    ...data,
    groups: data.groups.map((g) => ({ ...g, userCount: parseInt(g.userCount) })),
  };
});

export const fetchShareableUsers = createAsyncThunk<SharingUsersApiData, number>(
  "sharing/fetchShareableUsers",
  async (analysisId) => {
    const { data } = await getShareableUsers({ analysisId });

    if (!data) {
      throw new Error("Failed to fetch shareable users data");
    }

    return {
      itemCount: data.itemCount,
      totalCount: data.totalCount,
      users: data.users.map((u) => ({
        ...u,
        isSelected: u.shareStatus === ShareStatus.InDraft,
      })),
    };
  }
);

export const fetchDemographicHeaders = createAsyncThunk<DemographicFilterHeader[], number>(
  "sharing/fetchDemographicHeaders",
  async (analysisId) => {
    const { data } = await getDemographicsByAnalysisId({ analysisId });

    if (!data) {
      throw new Error("Failed to fetch demographic headers");
    }

    return data.analysis.demographics;
  }
);

type UpdateUsersInNewSharingDraftParams = {
  analysisId: number;
  userIds: number[];
  isSelected: boolean;
  sharingMethod: SharingMethod;
};

export const updateUsersInNewSharingDraft = createAsyncThunk<
  void,
  UpdateUsersInNewSharingDraftParams
>(
  "sharing/updateUsersInNewSharingDraft",
  async ({ analysisId, isSelected, sharingMethod, userIds }) => {
    const action = isSelected ? removeUsersFromNewSharingDraft : addUsersToNewSharingDraft;
    await action({
      analysisId,
      sharingMethod,
      userIds: userIds,
    });
  }
);

type RefetchSharingUsersDataParams = {
  analysisId: number;
  demographicFilters?: UserDemographic[];
};

export const refetchSharingUsersData = createAsyncThunk<
  SharingUsersApiData,
  RefetchSharingUsersDataParams,
  { state: AppState }
>("sharing/refetchSharingUsersData", async ({ analysisId, demographicFilters }, { getState }) => {
  const state = getState();

  const filterArray =
    demographicFilters ?? state.sharingUserSelection.selectedUserDemographicFilters;

  const demographics = filterArray.map((sf) => ({
    ...sf,
    method: DemographicFilterMethod.List,
  }));

  const { data } = await getShareableUsers(
    {
      analysisId,
      page: state.sharingUserSelection.currentPage,
      search: formatSearchForFetchRequest(state.search.searchTerm),
      sort_column: state.sharingUserSelection.sortingColumn,
      sort_order: state.sharingUserSelection.sortOrder,
    },
    demographics
  );

  if (!data) {
    throw new Error("Failed to refetch shareable users data");
  }

  return {
    itemCount: data.itemCount,
    totalCount: data.totalCount,
    users: data.users.map((u) => ({
      ...u,
      isSelected: u.shareStatus === ShareStatus.InDraft,
    })),
  };
});

type RemoveAllUsersFromDraftParams = {
  analysisId: number;
  sharingMethod: SharingMethod;
};

export const removeAllUsersFromDraft = createAsyncThunk<
  void,
  RemoveAllUsersFromDraftParams,
  { dispatch: AppDispatch }
>("sharing/removeAllUsersFromDraft", async ({ analysisId, sharingMethod }, { dispatch }) => {
  await removeUsersFromNewSharingDraft({
    analysisId,
    sharingMethod,
    userIds: [],
    removeAll: true,
  });
  dispatch(setSelectedUsers([]));
  dispatch(setSelectedUsersCount(0));
});

export const updateSelectedUsers = createAsyncThunk<
  void,
  number,
  { state: AppState; dispatch: AppDispatch }
>("sharing/updateSelectedUsers", async (analysisId, { getState, dispatch }) => {
  const state = getState();

  const { data } = await await getSharingDraftUsers({
    analysisId: analysisId,
    page: state.sharingUserSelection.pillsPage,
    limit: PILLS_LIMITS.REQUEST_LIMIT,
  });

  const uniqueUsersMap = new Map();

  state.sharingUserSelection.selectedUsers.forEach((user) => {
    uniqueUsersMap.set(user.id, user);
  });

  data.users.forEach((user) => uniqueUsersMap.set(user.id, user));

  const updatedArray = Array.from(uniqueUsersMap.values());

  const users = updatedArray.map((u, i) => {
    return {
      ...u,
      isShown:
        i <
        (state.sharingUserSelection.pillsPage - 1) * PILLS_LIMITS.REQUEST_LIMIT + PILLS_LIMITS.MIN,
    };
  });

  dispatch(setSelectedUsers(users));
  dispatch(setSelectedUsersCount(data.totalCount));
});

type GetSharingDraftParams = {
  analysisId: number;
  sharingMethod: SharingMethod;
  availableDemographicFilters: DemographicFilter[];
};

export const fetchSharingDraft = createAsyncThunk<
  SharingDraftPermissions,
  GetSharingDraftParams,
  { state: AppState }
>(
  "sharing/getSharingDraft",
  async (
    { analysisId, sharingMethod, availableDemographicFilters }: GetSharingDraftParams,
    { getState }
  ): Promise<SharingDraftPermissions> => {
    const { data } = await getSharingDraft(analysisId, sharingMethod);
    const state = getState();
    const userLanguage = state.auth.currentUser.preferredLanguage;
    const { getResource } = useResource(
      userLanguage,
      state.resourcesSlice.resources,
      state.resourcesSlice.topicResources
    );

    if (!data) {
      throw new Error("Failed to fetch sharing draft");
    }

    return {
      groupName: data.groupName ?? getResource("sharing.groupName.default"),
      ...(data.permissionLevel && { permissionLevel: data.permissionLevel }),
      commentExplorerAccess: data.commentExplorerAccess,
      topicExplorerAccess: data.topicExplorerAccess,
      dashboardAccess: data.dashboardAccess,
      defaultWidgets: data.defaultWidgets,
      allowExportData: data.allowExport,
      visibleRedactionVersion: state.sharing.redactionVersions.find(
        (version) => version.id === data.redactionVersionId
      ),
      redactionCustomConditions: data.customRedactionVersions ?? [],
      allowPin: data.allowPin,
      manualRedactionPermission: data.manualRedactionPermission,
      customDemographicConditions: data.customFilters ?? [],
      ...(data.demographicsToDisplay.length > 0 && {
        visibleDemographicsFilters:
          availableDemographicFilters.length !== 0
            ? data.demographicsToDisplay.map((d) =>
                getVisibleDemFiltersFromData(d, availableDemographicFilters)
              )
            : [],
      }),
      ...(data.demographicFilters.length > 0 && {
        visibleCommentsByDemographicValues:
          availableDemographicFilters.length !== 0
            ? data.demographicFilters
                .map((df) => getVisibleCommentDemsFromData(df, availableDemographicFilters))
                .filter(Boolean)
            : [],
      }),
    };
  }
);

export const saveSharingDraft = createAsyncThunk<void, number, { state: AppState }>(
  "sharing/saveSharingDraft",
  async (analysisId, { getState }) => {
    const state = getState();
    const requestBody = {
      method: getSharingMethodFromView(state.sharing.view),
      groupName: state.permissions.permissionFields.groupName,
      permissionLevel: state.permissions.permissionFields.permissionLevel,
      demographicFilters: state.permissions.permissionFields.visibleCommentsByDemographicValues.map(
        (d) => getVisibleCommentDemsForFetch(d)
      ),
      customFilters: state.permissions.permissionFields?.customDemographicConditions.filter(
        (cc) => cc.analysisDemographic.length > 0 && cc.userDemographic.length > 0
      ),
      demographicsToDisplay: state.permissions.permissionFields.visibleDemographicsFilters.map(
        (cd) => cd.name
      ),
      allowExport: state.permissions.permissionFields.allowExportData,
      overviewAccess: SharingOverviewAccess.Shared,
      defaultWidgets: state.permissions.permissionFields.defaultWidgets || DefaultWidgets.None,
      dashboardAccess: state.permissions.permissionFields.dashboardAccess,
      topicExplorerAccess: state.permissions.permissionFields.topicExplorerAccess,
      commentExplorerAccess: state.permissions.permissionFields.commentExplorerAccess,
      redactionVersionId: state.permissions.permissionFields?.visibleRedactionVersion?.id,
      customRedactionVersions: state.permissions.permissionFields.redactionCustomConditions.filter(
        (customCondition) =>
          customCondition.userDemographicValues.length > 0 &&
          customCondition.userDemographic.length > 0 &&
          customCondition.redactionVersionId
      ),
      allowPin: state.permissions.permissionFields.allowPin,
      manualRedactionPermission: state.permissions.permissionFields.manualRedactionPermission,
    };
    await updateSharingDraft({ analysisId, ...requestBody });
  }
);

type saveSharingDraftBySharingIdParams = {
  sharingId: number;
  groupName: string;
};

export const saveSharingDraftBySharingId = createAsyncThunk<
  void,
  saveSharingDraftBySharingIdParams,
  { state: AppState; dispatch: AppDispatch }
>(
  "sharing/saveSharingDraftBySharingId",
  async ({ sharingId, groupName }, { getState, dispatch }) => {
    const state = getState();

    const requestBody = {
      sharingId,
      groupName,
      demographicFilters: formatDemographicsForApi(state.editSharingBlock.selectedDemographics),
      customFilters: state.editSharingBlock.selectedCustomConditions.filter(
        (cc) => cc.analysisDemographic.length > 0 && cc.userDemographic.length > 0
      ),
      demographicsToDisplay: state.editSharingBlock.visibleDemographicsFilters.map((d) => d.name),
      allowExport: state.editSharingBlock.allowExportData,
      overviewAccess: SharingOverviewAccess.Shared,
      topicExplorerAccess: state.editSharingBlock.topicExplorerAccess,
      commentExplorerAccess: state.editSharingBlock.commentExplorerAccess,
      dashboardAccess: state.editSharingBlock.dashboardAccess,
      defaultWidgets: DefaultWidgets.None,
      method: SharingMethod.Group,
      permissionLevel: state.editSharingBlock.selectedPermissionLevel,
      allowPin: state.editSharingBlock.allowPin,
      redactionVersionId: state.editSharingBlock.selectedRedactionVersion?.id,
      customRedactionVersions: state.editSharingBlock.selectedRedactionCustomConditions.filter(
        (customCondition) =>
          customCondition.userDemographicValues.length > 0 &&
          customCondition.userDemographic.length > 0 &&
          customCondition.redactionVersionId
      ),
      manualRedactionPermission: state.editSharingBlock.manualRedactionPermission,
    };

    await updateSharingDraftBySharingId(requestBody).catch(() => {
      dispatch(showToastError("toast.defaultError"));
    });
  }
);

type fetchSharingDraftBySharingIdParams = {
  sharingId: number;
  availableDemographicFilters: DemographicFilter[];
};

export const fetchSharingDraftBySharingId = createAsyncThunk<
  SharingDraft,
  fetchSharingDraftBySharingIdParams,
  { state: AppState; dispatch: AppDispatch }
>(
  "sharing/fetchSharingDraftBySharingId",
  async ({ sharingId, availableDemographicFilters }, { getState, dispatch }) => {
    const state = getState();

    const { data } = await getSharingDraftBySharingId(
      sharingId,
      !state.sharedWith.returningFromPreview
    );

    if (!data) {
      throw new Error("Failed to fetch sharing draft by sharing id");
    }

    dispatch(setCommentExplorerAccess(data.commentExplorerAccess));
    dispatch(setDashboardAccess(data.dashboardAccess));
    dispatch(setTopicExplorerAccess(data.topicExplorerAccess));
    dispatch(setSelectedPermissionLevel(data.permissionLevel));
    dispatch(
      setVisibleDemographicsFilters(
        data.demographicsToDisplay.map((d) =>
          getVisibleDemFiltersFromData(d, availableDemographicFilters)
        )
      )
    );
    dispatch(setSelectedCustomConditions(data.customFilters));
    dispatch(setAllowExportData(data.allowExport));
    dispatch(
      setSelectedDemographics(
        data.demographicFilters
          .map((d) => getVisibleCommentDemsFromData(d, availableDemographicFilters))
          .filter(Boolean)
      )
    );

    dispatch(
      setSelectedRedactionVersion(
        state.sharing.redactionVersions.find(
          (redactionVersion) => redactionVersion.id === data.redactionVersionId
        )
      )
    );
    dispatch(setSelectedRedactionCustomConditions(data.customRedactionVersions ?? []));
    dispatch(setAllowPin(data.allowPin));
    dispatch(setManualRedactionPermission(data.manualRedactionPermission));
    dispatch(setAllDataLoaded(true));

    return data;
  }
);

type fetchSharingUsersDataParams = {
  sharingId: number;
  analysisId: number;
  demographicFilters?: UserDemographic[];
};

export const fetchSharingUsersData = createAsyncThunk<
  void,
  fetchSharingUsersDataParams,
  { state: AppState; dispatch: AppDispatch }
>(
  "sharing/fetchSharingUsersData",
  async ({ analysisId, sharingId, demographicFilters }, { getState, dispatch }) => {
    const state = getState();

    const fetchParams = {
      page: state.userSelectionPopup.currentPage,
      limit: DEFAULT_LIST_LIMIT,
      search: formatSearchForFetchRequest(state.search.modalSearchTerm),
      sort_column: state.userSelectionPopup.sortingColumn,
      sort_order: state.userSelectionPopup.sortOrder,
    };

    const filterArray =
      demographicFilters ?? state.sharingUserSelection.selectedUserDemographicFilters;

    const demographics = filterArray.map((sf) => ({
      ...sf,
      method: DemographicFilterMethod.List,
    }));

    const { data } = await getUsersInSharingDraft({ sharingId, ...fetchParams });

    if (!data) {
      throw new Error("Failed to fetch sharing users data");
    }

    if (!state.editSharingBlock.isModalOpen) {
      dispatch(setSelectedUsersCountInSharing(data.totalCount));
    }

    if (state.editSharingBlock.editGroupModalView === EditGroupModalView.edit) {
      dispatch(setAvailableUsers(data.users));
      dispatch(setTotalUserCount(data.totalCount));
      dispatch(setCurrentUserCount(data.itemCount));
    }

    if (state.editSharingBlock.editGroupModalView === EditGroupModalView.add) {
      const { data: shareableUsers } = await getShareableUsers(
        { analysisId, ...fetchParams },
        demographics
      );
      dispatch(
        setAvailableUsers(
          shareableUsers.users.map((user) => {
            const isSelected = data.users.some((u) => u.id === user.id);
            return { ...user, isSelected };
          })
        )
      );
      dispatch(setTotalUserCount(shareableUsers.totalCount));
      dispatch(setCurrentUserCount(shareableUsers.itemCount));
    }
  }
);

export const fetchRedactionVersions = createAsyncThunk<RedactionVersion[], number>(
  "fetchRedactionVersions",
  async (analysisId: number): Promise<RedactionVersion[]> => {
    const { data } = await getAnalysisRedactionVersions(analysisId);
    return data.redactionVersions;
  }
);
