import React, { useEffect, useState } from "react";

import { useAppDispatch } from "store";
import { clearState, setComment } from "store/pin/pinSlice";
import useClickOutside from "hooks/useClickOutside";

import { Icon, IconType } from "components/_icons/Icon";
import { Button } from "components/_buttons/Button";
import { PinPopup } from "./PinPopup";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { Comment } from "ts/comments";

type Props = {
  comment: Comment;
  refetchComments: () => void;
  disabled?: boolean;
};

export const PinActionButton = ({ comment, disabled, refetchComments }: Props) => {
  const dispatch = useAppDispatch();

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const pinActionIconRef = useClickOutside(() => setIsPopupOpen(false));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setIsPopupOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
    dispatch(
      setComment(
        comment.pin?.category
          ? comment
          : {
              ...comment,
              pin: {
                category: "",
                tags: [],
                note: null,
              },
            }
      )
    );
  };

  useEffect(() => {
    if (!isPopupOpen) {
      dispatch(clearState());
    }
  }, [isPopupOpen, dispatch]);

  return (
    <div ref={pinActionIconRef}>
      <Button
        variant={ButtonVariant.light}
        square
        disabled={disabled}
        tooltipContentKey="comments.pinComment.tooltip"
        active={isPopupOpen}
        size={ButtonSize.sm}
        onClick={handleClick}
        style={{
          color: (comment?.pin ? Color.indigo50 : Color.gray20) + "!important",
        }}
      >
        <Icon type={IconType.pin} color={Color.gray20} size={12} />
      </Button>
      {isPopupOpen && (
        <PinPopup
          anchorEl={anchorEl}
          setIsPopupOpen={setIsPopupOpen}
          refetchComments={refetchComments}
        />
      )}
    </div>
  );
};
