import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { setIsSummaryAnimationComplete } from "store/summary/summarySlice";
import styled from "styled-components";

type Props = {
  text: string;
  speed?: number;
};

export const AnimatedText = ({ text, speed = 25 }: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.summary);

  const [displayedText, setDisplayedText] = useState("");
  const [isCursorVisible, setIsCursorVisible] = useState(speed !== 0);

  useEffect(() => {
    let index = 0;
    setDisplayedText(speed !== 0 ? "" : text);

    if (speed === 0) return;

    const interval = setInterval(() => {
      if (index < text.length) {
        const chunkSize = Math.floor(Math.random() * (8 - 3 + 1)) + 3;
        const nextChunk = text.slice(index, index + chunkSize);

        setDisplayedText((prev) => prev + nextChunk);
        index += chunkSize;
      } else {
        dispatch(setIsSummaryAnimationComplete(true));
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed, dispatch]);

  useEffect(() => {
    if (speed === 0) return;
    const cursorInterval = setInterval(() => {
      setIsCursorVisible((prev) => !prev);
    }, 500);

    if (state.isSummaryAnimationComplete) {
      clearInterval(cursorInterval);
      setIsCursorVisible(false);
    }

    return () => clearInterval(cursorInterval);
  }, [state.isSummaryAnimationComplete]); //eslint-disable-line

  return (
    <StyledAnimatedText>
      {displayedText}
      <span style={{ visibility: isCursorVisible ? "visible" : "hidden" }}>|</span>
    </StyledAnimatedText>
  );
};

const StyledAnimatedText = styled.div`
  white-space: pre-wrap;
  display: inline-block;
  span {
    display: inline-block;
    width: 3px;
    background-color: black;
    animation: blink 1s step-end infinite;
  }

  @keyframes blink {
    50% {
      visibility: hidden;
    }
  }
`;
