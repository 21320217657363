// librarie
import { User } from "oidc-client";
import axios from "axios";

// common and config
import { AuthenticationManager } from "./authenticationManager";
import { config } from "./configProviders";

const getAccessToken = async () => {
  // Check if there's already a token in session storage
  const fromSessionStorage = window.localStorage.getItem(
    `oidc.user:${config.server.authentication.authority}:${config.server.authentication.appClientId}`
  );
  const currentSession = JSON.parse(fromSessionStorage);
  const currentTime = new Date().getTime() / 1000;

  // If there is and it hasn't expired, return it
  if (currentSession.access_token && currentTime < currentSession.expires_at) {
    return currentSession.access_token;
  }

  // Else use the AuthenticationManager to get or renew the token

  const authenticationManager = new AuthenticationManager();
  let oidcUser: User | void = await authenticationManager.getUser();

  if (oidcUser && oidcUser.expired) {
    oidcUser = await authenticationManager.silentRefresh();
  }

  return oidcUser && oidcUser.access_token;
};

export const mlyApi = axios.create({
  baseURL: `${config.server.baseApi}/api/${config.server.apiVersion}`,
  headers: {
    "Content-type": "application/json",
  },
});

mlyApi.interceptors.request.use((config) => {
  config.headers["request-startTime"] = new Date().getTime();
  return config;
});

mlyApi.interceptors.response.use((response) => {
  const currentTime = new Date().getTime();
  const startTime = response.config.headers["request-startTime"];
  response.headers["request-duration"] = currentTime - startTime;
  return response;
});

export const getHeaders = async (contentType = "application/json") => {
  const accessToken = await getAccessToken();
  return {
    headers: {
      "Content-type": contentType,
      authorization: "Bearer " + accessToken,
    },
  } as any;
};
