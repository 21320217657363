import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useAppSelector, useAppDispatch } from "store";

import useClickOutside from "hooks/useClickOutside";
import { validateEmail } from "utils/validation";
import { updateActiveLicencePeriod } from "services/licensing";
import { showToastError } from "store/toast/toastSlice";
import {
  setCommentsLimitInputValue,
  setEmailNotificationThresholdInputValue,
  setEmailToNotifyInputValue,
  setIsEmailError,
  setIsManagerEmailError,
  setManagerEmailToNotifyInputValue,
  setShowQuotaDropdown,
  setUserLimitInputValue,
} from "store/licenseManagement/quotaMenuSlice";
import { fetchLicensePeriodById, fetchLicensePeriods } from "store/licenseManagement/thunks";

import { StyledDropdownTransition } from "components/DropdownMenu/StyledDropdownTransition";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";
import { NumberInput } from "components/_inputs/NumberInput";
import { Text } from "components/Text";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { ErrorType, RoleType } from "@explorance/mly-types";

const COMMENTS_LIMIT_INPUT_RANGE = { min: 1, max: 9999999 };
const USERS_LIMIT_INPUT_RANGE = { min: 1, max: 99999 };
const EMAIL_NOTIFICATION_INPUT_RANGE = { min: 1, max: 100 };

type Props = {
  periodId: number;
};

export const QuotaMenu = ({ periodId }: Props) => {
  const { currentUser } = useAppSelector((state) => state.auth);
  const state = useAppSelector((state) => state.quotaMenu);
  const dispatch = useAppDispatch();

  const quotaMenuRef = useClickOutside(() => dispatch(setShowQuotaDropdown(false)));

  const isInputInactive = currentUser.roleType === RoleType.Admin || !state.isActivePeriod;

  const inputStyling = {
    height: "34px",
    margin: "0 6px",
    border: `1px solid ${Color.sky50}`,
    borderRadius: "2px",
    padding: "0 6px",
    color: `${Color.gray50}`,
    width: "96px",
  };

  const handleSave = async () => {
    const isEmailInputEmpty = !state.emailToNotifyInputValue;
    const isEmailValid = !isEmailInputEmpty || validateEmail(state.emailToNotifyInputValue);

    const isManagerEmailInputEmpty = !state.managerEmailNotifyInputValue;
    const isManagerEmailValid =
      !isManagerEmailInputEmpty || validateEmail(state.managerEmailNotifyInputValue);

    dispatch(setIsEmailError(!isEmailValid));
    dispatch(setIsManagerEmailError(!isManagerEmailValid));

    if (!isEmailValid || !isManagerEmailValid) return;
    try {
      const requestBody =
        currentUser.roleType === RoleType.SuperAdmin
          ? {
              commentLimit: state.commentsLimitInputValue,
              userLimit: state.userLimitInputValue,
              notificationQuota: state.emailNotificationThresholdInputValue,
              notificationEmails: [state.emailToNotifyInputValue],
              accountManagerEmails: [state.managerEmailNotifyInputValue],
            }
          : {
              notificationQuota: state.emailNotificationThresholdInputValue,
              notificationEmails: [state.emailToNotifyInputValue],
              accountManagerEmails: [state.managerEmailNotifyInputValue],
            };
      await updateActiveLicencePeriod(requestBody);
      dispatch(fetchLicensePeriods());
      dispatch(setShowQuotaDropdown(false));
    } catch (error: any) {
      dispatch(
        showToastError(
          error.response?.data?.details?.errorType === ErrorType.UsersLimitReached
            ? {
                message: {
                  titleKey: "licensing.quotaMenu.error.belowExistingUserCount",
                  args: [{ titleKeyArg: error.response.data.details.count }],
                },
              }
            : "toast.defaultError"
        )
      );
    }
  };

  // Fetching the current period's quota values
  useEffect(() => {
    dispatch(fetchLicensePeriodById(periodId));
  }, []); // eslint-disable-line

  return (
    <StyledQuotaMenu ref={quotaMenuRef}>
      <Button
        variant={ButtonVariant.secondary}
        size={ButtonSize.md}
        onClick={() => dispatch(setShowQuotaDropdown(!state.showQuotaDropdown))}
      >
        <span>
          <Text resource="licensing.quotaMenu.button" />
        </span>
        <Icon type={IconType.chevronRight} size={14} style={{ marginLeft: "6px" }} />
      </Button>
      <StyledQuotaDropdown show={state.showQuotaDropdown}>
        <h3>
          <Text resource="licensing.quotaMenu.header" />
        </h3>
        <>
          <StyledQuotaInputField>
            <span>
              <Text resource="licensing.quotaMenu.commentSentenceField.label1" />
            </span>
            <NumberInput
              value={state.commentsLimitInputValue}
              minimum={COMMENTS_LIMIT_INPUT_RANGE.min}
              maximum={COMMENTS_LIMIT_INPUT_RANGE.max}
              disabled={isInputInactive}
              styling={inputStyling}
              handleChange={(val) => dispatch(setCommentsLimitInputValue(val))}
            />
            <span>
              <Text resource="licensing.quotaMenu.commentSentenceField.label2" />
            </span>
          </StyledQuotaInputField>
          <StyledQuotaInputField>
            <span>
              <Text resource="licensing.quotaMenu.userQuotaField.label1" />
            </span>
            <NumberInput
              value={state.userLimitInputValue}
              minimum={USERS_LIMIT_INPUT_RANGE.min}
              maximum={USERS_LIMIT_INPUT_RANGE.max}
              allowNull
              disabled={isInputInactive}
              styling={inputStyling}
              handleChange={(val) => dispatch(setUserLimitInputValue(val))}
            />
            <span>
              <Text resource="licensing.quotaMenu.userQuotaField.label2" />
            </span>
          </StyledQuotaInputField>

          <StyledHorizontalSeparator />
        </>
        <StyledQuotaInputField>
          <span>
            <Text resource="licensing.quotaMenu.emailNotificationQuotaField.label1" />
          </span>
          <NumberInput
            value={state.emailNotificationThresholdInputValue}
            minimum={EMAIL_NOTIFICATION_INPUT_RANGE.min}
            maximum={EMAIL_NOTIFICATION_INPUT_RANGE.max}
            styling={{
              ...inputStyling,
              width: "40px",
            }}
            disabled={!state.isActivePeriod}
            handleChange={(val) => dispatch(setEmailNotificationThresholdInputValue(val))}
          />
          <span>
            <Text resource="licensing.quotaMenu.emailNotificationQuotaField.label2" />
          </span>
        </StyledQuotaInputField>
        {(state.isEmailError || state.isManagerEmailError) && (
          <StyledEmailError>
            <Icon
              type={IconType.warning}
              color={Color.red50}
              size={16}
              style={{ marginRight: 5 }}
            />
            <Text resource="licensing.quotaMenu.emailField.error" />
          </StyledEmailError>
        )}

        <StyledQuotaInputField>
          <Text resource="licensing.quotaMenu.emailField.label" />
          <StyledEmailInput
            value={state.emailToNotifyInputValue}
            onChange={(e) => dispatch(setEmailToNotifyInputValue(e.target.value))}
            isError={state.isEmailError}
            style={inputStyling}
            disabled={!state.isActivePeriod}
          />
        </StyledQuotaInputField>

        {currentUser.roleType === RoleType.SuperAdmin && (
          <StyledQuotaInputField>
            <Text resource="licensing.quotaMenu.managerEmailField.label" />
            <StyledEmailInput
              value={state.managerEmailNotifyInputValue}
              onChange={(e) => dispatch(setManagerEmailToNotifyInputValue(e.target.value))}
              isError={state.isManagerEmailError}
              style={inputStyling}
              disabled={!state.isActivePeriod}
            />
          </StyledQuotaInputField>
        )}

        <StyledSubmitButtonsContainer>
          <Button
            size={ButtonSize.sm}
            style={{ marginRight: 12 }}
            variant={ButtonVariant.light}
            onClick={() => dispatch(setShowQuotaDropdown(false))}
          >
            <Text resource="button.cancel" />
          </Button>
          <Button size={ButtonSize.sm} onClick={handleSave} disabled={!state.isActivePeriod}>
            <Text resource="button.save" />
          </Button>
        </StyledSubmitButtonsContainer>
      </StyledQuotaDropdown>
    </StyledQuotaMenu>
  );
};

const StyledQuotaMenu = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  h3 {
    margin: 2px 0 12px 0;
  }
`;

const StyledQuotaDropdown = styled(StyledDropdownTransition)<{ show: boolean }>`
  position: absolute;
  right: 0;
  top: 100%;
  background-color: ${Color.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 12px 16px;
  border-radius: 5px;
  width: 422px;

  * {
    font-size: 15px;
  }
`;

const StyledQuotaInputField = styled.div`
  padding-bottom: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    display: inline;
    font-size: 15px;
  }
`;

const StyledEmailInput = styled.input<{ isError: boolean }>`
  border: 1px solid ${Color.sky50} !important;
  flex-grow: 1;
  outline-style: none;
  :focus {
    border: 1px solid ${({ isError }) => (isError ? Color.red50 : Color.blue50)} !important;
  }

  ${({ isError }) =>
    isError &&
    css`
      border: 1px solid ${Color.red50} !important;
      background-color: ${Color.red10};
    `}
`;

const StyledEmailError = styled.div`
  display: flex;
  align-items: center;
  color: ${Color.red50};
  margin-bottom: 8px;
`;

const StyledHorizontalSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Color.sky50};
  margin-bottom: 12px;
`;

const StyledSubmitButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
