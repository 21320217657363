import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Icon, IconType } from "components/_icons/Icon";
import { Pill } from "components/_pills/Pill";
import { Text } from "components/Text";

import { Color } from "ts/enums/color";

const MODAL_CONTENT_WIDTH = 530;

const hints = [
  {
    title: "modal.import.stepOne.hints.hint1.title",
    description: "modal.import.stepOne.hints.hint1.description",
  },
  {
    title: "modal.import.stepOne.hints.hint2.title",
    description: "modal.import.stepOne.hints.hint2.description",
  },
  {
    title: "modal.import.stepOne.hints.hint3.title",
    description: "modal.import.stepOne.hints.hint3.description",
  },
  {
    title: "modal.import.stepOne.hints.hint4.title",
    description: "modal.import.stepOne.hints.hint4.description",
  },
  {
    title: "modal.import.stepOne.hints.hint5.title",
    description: "modal.import.stepOne.hints.hint5.description",
  },
  {
    title: "modal.import.stepOne.hints.hint6.title",
    description: "modal.import.stepOne.hints.hint6.description",
  },
];

export const StepOneModalHints = () => {
  const [currentHint, setCurrentHint] = useState<number>(0);
  const [shouldStopTimer, setShouldStopTimer] = useState<boolean>(false);
  const [translate3dXValue, setTranslate3dXValue] = useState<string>("0px");

  const carouselTimer = useRef<NodeJS.Timeout | null>(null);

  const isLastHint = currentHint + 1 >= hints.length;

  const handleClickDot = (index: number) => {
    setCurrentHint(index);
    setTranslate3dXValue(`${-index * MODAL_CONTENT_WIDTH}px`);
    clearTimeout(carouselTimer.current);
    setShouldStopTimer(true);
  };

  const handleCarouselTimer = useCallback(() => {
    const index = isLastHint ? 0 : currentHint + 1;
    setCurrentHint(index);
    setTranslate3dXValue(`${-index * MODAL_CONTENT_WIDTH}px`);
  }, [currentHint, isLastHint]);

  useEffect(() => {
    if (!shouldStopTimer) {
      carouselTimer.current = setTimeout(() => handleCarouselTimer(), 6000);
    }
  }, [handleCarouselTimer, shouldStopTimer]);

  return (
    <StyledCarouselContainer>
      <StyledArrowButton
        onClick={() => currentHint !== 0 && handleClickDot(currentHint - 1)}
        disabled={currentHint === 0}
      >
        <Icon
          size={16}
          type={IconType.arrowLeft}
          color={currentHint === 0 ? Color.gray20 : Color.gray40}
        />
      </StyledArrowButton>

      <StyledMainContentContainer>
        <StyledImage />
        <StyledHintsContainer>
          <Pill
            style={{
              padding: "5px 12px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
            round
          >
            <StyledIconContainer>
              <Icon type={IconType.lightBulb} color={Color.indigo50} />
            </StyledIconContainer>
            <Text resource="modal.import.stepOne.hints.pill" />
          </Pill>

          <StyledHints>
            {hints.map((hint, index) => (
              <StyledHint key={index} translate3dXValue={translate3dXValue}>
                <StyledHintTitle>
                  <Text resource={hint.title} />
                </StyledHintTitle>
                <StyledHintDescription>
                  <Text resource={hint.description} />
                </StyledHintDescription>
              </StyledHint>
            ))}
          </StyledHints>
        </StyledHintsContainer>

        <StyledDotContainer>
          {hints.map((hint, index) => (
            <span
              key={hint.title}
              className={index === currentHint ? "active" : undefined}
              onClick={() => {
                handleClickDot(index);
              }}
            />
          ))}
        </StyledDotContainer>
      </StyledMainContentContainer>
      <StyledArrowButton
        disabled={isLastHint}
        onClick={() => !isLastHint && handleClickDot(currentHint + 1)}
      >
        <Icon
          size={16}
          type={IconType.arrowRight}
          color={isLastHint ? Color.gray20 : Color.gray40}
        />
      </StyledArrowButton>
    </StyledCarouselContainer>
  );
};

const StyledCarouselContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
`;

const StyledMainContentContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const StyledImage = styled.div`
  border-radius: 50%;
  background: ${Color.gray20};
  width: 156px;
  height: 156px;
`;

const StyledIconContainer = styled.div`
  width: 16px;
  height: 16px;
`;

const StyledHintsContainer = styled.div`
  padding: 24px;
  width: 530px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`;

const StyledHints = styled.div`
  display: flex;
  width: 530px;
  overflow-x: hidden;
`;

const StyledHint = styled.div<{ translate3dXValue: string }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
  transform: ${({ translate3dXValue }) => `translate3d(${translate3dXValue}, 0px, 0)`};
  transition: transform 0.5s ease-in-out;
`;

const StyledHintTitle = styled.div`
  color: ${Color.gray50};
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
`;

const StyledHintDescription = styled.div`
  width: 482px;
  color: ${Color.gray30};
  text-align: center;
`;

const StyledDotContainer = styled.div`
  text-align: center;

  span {
    cursor: pointer;
    display: inline-block;
    background-color: ${Color.indigo30};
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 6px;
  }

  .active {
    background-color: ${Color.indigo50};
  }
`;

const StyledArrowButton = styled.button<{ disabled: boolean }>`
  all: unset;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: ${Color.sky15};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ disabled }) => (disabled ? "none" : `-2px 2px 5px 0px #00000033`)};
  cursor: pointer;
`;
