import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserDemographic } from "context/UserProfileContext";
import { SharingUser, SharingUsersApiData } from "ts/sharing";
import { fetchShareableUsers, refetchSharingUsersData } from "./thunks";
import { cloneDeep } from "lodash";
import { validateUsersShown } from "utils/sharing";
import { ShareableUsersSortingParameter, SortingOrder } from "@explorance/mly-types";

type userSelectionState = {
  shareableUsersApiData: SharingUsersApiData;
  selectedUsers: SharingUser[];
  selectedUsersCount: number;
  selectedUserDemographicFilters: UserDemographic[];
  pillsPage: number;
  sortingColumn: ShareableUsersSortingParameter;
  sortOrder: SortingOrder;
  currentPage: number;
  isUserDemographicFiltersReset: boolean;
  isSharableUsersLoading: boolean;
};

const initialState: userSelectionState = {
  shareableUsersApiData: {
    users: [],
    itemCount: null,
    totalCount: null,
  },
  selectedUsers: [],
  selectedUsersCount: 0,
  selectedUserDemographicFilters: [],
  pillsPage: 1,
  sortingColumn: ShareableUsersSortingParameter.FirstName,
  sortOrder: SortingOrder.ASC,
  currentPage: 1,
  isUserDemographicFiltersReset: false,
  isSharableUsersLoading: true,
};

const userSelectionSlice = createSlice({
  name: "userSelection",
  initialState,
  reducers: {
    setShareableUsersApiData: (state, action: PayloadAction<SharingUsersApiData>) => {
      state.shareableUsersApiData = action.payload;
    },
    setSelectedUsers: (state, action: PayloadAction<SharingUser[]>) => {
      state.selectedUsers = action.payload;
    },
    setSelectedUsersCount: (state, action: PayloadAction<number>) => {
      state.selectedUsersCount = action.payload;
    },
    setSelectedUserDemographicFilters: (state, action: PayloadAction<UserDemographic[]>) => {
      state.selectedUserDemographicFilters = action.payload;
    },
    setPillsPage: (state, action: PayloadAction<number>) => {
      state.pillsPage = action.payload;
    },
    setSortingColumn: (state, action: PayloadAction<ShareableUsersSortingParameter>) => {
      state.sortingColumn = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<SortingOrder>) => {
      state.sortOrder = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setIsUserDemographicFiltersReset: (state, action: PayloadAction<boolean>) => {
      state.isUserDemographicFiltersReset = action.payload;
    },
    deselectAllUsers: (state) => {
      const apiData = cloneDeep(state.shareableUsersApiData);
      apiData.users.forEach((u) => {
        if (u.isSelected) {
          u.isSelected = false;
        }
      });
      state.shareableUsersApiData = apiData;
      state.selectedUsers = [];
    },
    deletePillByUserId: (state, action: PayloadAction<number>) => {
      const filteredSelectedUsers = state.selectedUsers.filter((u) => u.id !== action.payload);
      state.selectedUsers = validateUsersShown(state.selectedUsers, filteredSelectedUsers);
      state.selectedUsersCount = state.selectedUsers.length;

      const apiData = cloneDeep(state.shareableUsersApiData);
      const apiUserIndex = apiData.users.findIndex((c) => c.id === action.payload);
      if (apiUserIndex !== -1) {
        apiData.users.splice(apiUserIndex, 1, {
          ...apiData.users[apiUserIndex],
          isSelected: !apiData.users[apiUserIndex].isSelected,
        });
      }
      state.shareableUsersApiData = apiData;
    },
    clearUserSelectionState: () => initialState,
  },

  extraReducers: (builder) => {
    builder.addCase(fetchShareableUsers.fulfilled, (state, action) => {
      state.shareableUsersApiData = action.payload;
      state.isSharableUsersLoading = false;
    });
    builder.addCase(refetchSharingUsersData.fulfilled, (state, action) => {
      state.shareableUsersApiData = action.payload;
    });
  },
});

export const {
  setShareableUsersApiData,
  setSelectedUsers,
  setSelectedUsersCount,
  setSelectedUserDemographicFilters,
  setPillsPage,
  setSortingColumn,
  setSortOrder,
  setCurrentPage,
  setIsUserDemographicFiltersReset,
  clearUserSelectionState,
  deselectAllUsers,
  deletePillByUserId,
} = userSelectionSlice.actions;

export default userSelectionSlice.reducer;
