import { Color } from "ts/enums/color";

export const getTagStyles = () => {
  return {
    control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? ` 1px solid ${Color.blue50}` : `1px solid ${Color.sky50}`,
      cursor: "text",
      boxShadow: "0",
      borderRadius: "2px",
      padding: "8px 10px",
      maxHeight: "100px",
      overflowY: "auto",
      "&:hover": {
        borderColor: "none",
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0",
      gap: "5px",
    }),
    input: () => ({
      overflow: "hidden",
      flexGrow: "1",
    }),
    multiValue: () => ({
      display: "flex",
      alignContent: "center",
      height: "24px",
      backgroundColor: Color.indigo10,
      border: `1px solid ${Color.indigo20}`,
      borderRadius: "12px",
      color: Color.indigo50,
      padding: "4px 12px",
      gap: "3px",
      margin: "0 5px 0 0",
    }),
    multiValueLabel: () => ({
      fontSize: "12px",
      color: Color.indigo50,
      fontWeight: "bold",
      maxWidth: "200px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    multiValueRemove: () => ({
      display: "flex",
      alignContent: "center",
      color: Color.indigo50,
      cursor: "pointer",
      transform: "scale(1.15)",
    }),
    menu: (provided) => ({
      ...provided,
      margin: "2px",
      position: "absolute",
      overflowY: "auto",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "10px",
      cursor: "pointer",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: Color.gray20,
      position: "absolute",
      width: "100%",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
  };
};

export const getCategoryStyles = (isCategoryActive: boolean) => {
  {
    return {
      control: (baseStyles, state) => ({
        ...baseStyles,
        border: state.isFocused ? ` 1px solid ${Color.blue50}` : `1px solid ${Color.sky50}`,
        boxShadow: "none",
        cursor: "text",
        borderRadius: "2px",
        "&:hover": {
          borderColor: "none",
        },
      }),
      singleValue: (provided) => {
        return {
          ...provided,
          color: isCategoryActive ? Color.gray20 : Color.gray50,
        };
      },

      menu: (provided) => ({
        ...provided,
        margin: "3px 0 0 0",
        overflowY: "auto",
      }),
      menuList: (provided) => ({
        ...provided,
        maxHeight: "130px",
      }),
      placeholder: (provided) => ({
        ...provided,
        color: Color.gray20,
      }),
      option: (provided) => ({
        ...provided,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding: "10px",
        cursor: "pointer",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        cursor: "pointer",
      }),
      clearIndicator: (provided) => ({
        ...provided,
        cursor: "pointer",
      }),
    };
  }
};
