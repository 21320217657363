import { without } from "lodash";
import { CSSProperties } from "styled-components";
import {
  RecommendationType,
  SentimentType,
  StatisticsType,
  TopicsListView,
} from "@explorance/mly-types";

import { Color } from "ts/enums/color";
import { StackedBarChartCount } from "ts/overview";
import { TopicModalData } from "ts/topic";
import {
  formatRecommendationsForStackedBarChart,
  formatSentimentsForStackedBarChart,
} from "utils/overview";

export const getInitialStat = (
  topic: TopicModalData,
  initialView: TopicsListView
): StatisticsType => {
  if (!topic) return;
  if (topic.dominantSentiment) return StatisticsType[topic.dominantSentiment];
  const stat = statBuilder(topic, initialView)[0];
  return stat && (stat.attribute as unknown as StatisticsType);
};

export const getAvailableStats = (view: TopicsListView) => {
  const allStats = [
    ...without(
      Object.keys(RecommendationType),
      RecommendationType.generalRecommendation,
      RecommendationType.recommendation
    ),
    ...Object.keys(SentimentType),
  ];

  return allStats.filter((t) => {
    switch (view) {
      case TopicsListView.Recommendations:
        return Object.keys(RecommendationType).includes(t);
      case TopicsListView.Sentiments:
        return Object.keys(SentimentType).includes(t);
      default:
        return false;
    }
  });
};

export const statBuilder = (
  topic: TopicModalData,
  view: TopicsListView
): StackedBarChartCount[] => {
  if (!topic) return;
  const total = topic.insights || topic.recommendations;
  if (view === TopicsListView.Sentiments) {
    const insightStats = Object.entries(topic)
      .filter(([key, value]) => key.includes(SentimentType[key]) && [key, value])
      .map(([key, value]) => [key, (value as number) / total])
      .sort((a, b) => (b[1] as number) - (a[1] as number));

    return insightStats.map((stat) => formatSentimentsForStackedBarChart(stat as [string, number]));
  } else {
    const recommendationStats = Object.entries(topic)
      .filter(([key, value]) => key.includes(RecommendationType[key]) && [key, value])
      .map(([key, value]) => [key, value as number])
      .sort((a, b) => (b[1] as number) - (a[1] as number));
    return recommendationStats.map((stat) =>
      formatRecommendationsForStackedBarChart(stat as [string, number], total)
    );
  }
};

export const getModalStyles = (commentLimit: number, relevantCommentsLength: number) => {
  const defaultHeight = "607px";
  const increasedHeight = "670px";

  const height =
    (commentLimit === 10 || commentLimit === 20) && relevantCommentsLength > 5
      ? increasedHeight
      : defaultHeight;

  return {
    content: {
      position: "relative" as CSSProperties["position"],
      left: "50%",
      top: "45%",
      transform: "translate(-50%, -50%)",
      boxShadow: "0px 4px 16px #B6BACB29",
      padding: "25px",
      width: "1000px",
      height,
      border: `1px solid ${Color.neutral30}`,
      borderRadius: "5px",
      backgroundColor: Color.white,
      transition: "height .5s ease-in-out",
      overflow: "hidden",
    },
  };
};
