import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";

import { AnalysisContext } from "context/AnalysisContext";
import { setAnalysisError } from "context/AnalysisContext/actions";
import { useResource } from "hooks/useResource";

import noAnalysisSvg from "assets/images/topics-no-analysis.svg";
import noTopicSvg from "assets/images/topics-no-topic.svg";
import insufficientPermissionSvg from "assets/images/insufficient-permission.svg";
import analysisNotAvailable from "assets/images/analysis-not-available.svg";
import analysisFailed from "assets/images/analysis-failed.svg";
import noUsersSelected from "assets/images/no-users-selected.svg";

import { PageErrorType } from "ts/enums/pageErrorType";

import { routes } from "routes";
import { Button } from "./_buttons/Button";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Icon, IconType } from "components/_icons/Icon";
import { Color } from "ts/enums/color";
import { Text } from "./Text";
import { isAnyAdmin } from "utils/isAnyAdmin";
import { useAppSelector } from "store";

const errorData = {
  [PageErrorType.AnalysisNotFound]: {
    src: noAnalysisSvg,
    alt: "errorScreen.analysisNotFound.imgAlt",
    title: "errorScreen.analysisNotFound.title",
    description: "errorScreen.analysisNotFound.description",
  },
  [PageErrorType.AnalysisInsufficientPermission]: {
    src: insufficientPermissionSvg,
    alt: "errorScreen.insufficientPermission.imgAlt",
    title: "errorScreen.analysisInsufficientPermission.title",
    description: "errorScreen.generalInsufficientPermission.description",
  },
  [PageErrorType.TopicNotFound]: {
    src: noTopicSvg,
    alt: "errorScreen.analysisNoTopicFound.imgAlt",
    title: "errorScreen.analysisNoTopicFound.title",
    description: "errorScreen.analysisNoTopicFound.description",
  },
  [PageErrorType.CustomModelInsufficientPermission]: {
    src: insufficientPermissionSvg,
    alt: "errorScreen.insufficientPermission.imgAlt",
    title: "errorScreen.cmInsufficientPermission.title",
    description: "errorScreen.generalInsufficientPermission.description",
  },
  [PageErrorType.VariableMappingInsufficientPermission]: {
    src: insufficientPermissionSvg,
    alt: "errorScreen.insufficientPermission.imgAlt",
    title: "errorScreen.vmInsufficientPermission.title",
    description: "errorScreen.generalInsufficientPermission.description",
  },
  [PageErrorType.GeneralInsufficientPermission]: {
    src: insufficientPermissionSvg,
    alt: "errorScreen.insufficientPermission.imgAlt",
    title: "errorScreen.generalInsufficientPermission.title",
    description: "errorScreen.generalInsufficientPermission.description",
  },
  [PageErrorType.AnalysisNotAnalyzed]: {
    src: analysisNotAvailable,
    alt: "errorScreen.analysisNotAvailable.imgAlt",
    title: "errorScreen.analysisNotAvailable.title",
    description: "errorScreen.analysisNotAvailable.description",
  },
  [PageErrorType.AnalysisFailed]: {
    src: analysisFailed,
    alt: "errorScreen.analysisFailed.imgAlt",
    title: "errorScreen.analysisFailed.title",
    description: "errorScreen.analysisFailed.description",
  },
  [PageErrorType.RedactionFeatureNotActivated]: {
    src: insufficientPermissionSvg,
    alt: "errorScreen.insufficientPermission.imgAlt",
    title: "errorScreen.redactionFeatureNotActivated.title",
    description: "errorScreen.redactionFeatureNotActivated.description",
  },
  [PageErrorType.NoUsersSelected]: {
    src: noUsersSelected,
    alt: "errorScreen.noUsersSelected.imgAlt",
    title: "errorScreen.noUsersSelected.title",
    description: "errorScreen.noUsersSelected.description",
  },
};

type Props = {
  errorType: PageErrorType;
};

export const ErrorScreen = ({ errorType }: Props) => {
  const history = useHistory();
  const { getResource } = useResource();
  const userRole = useAppSelector((state) => state.auth.currentUser.roleType);
  const dispatch = useContext(AnalysisContext)?.[1];
  const { analysisId } = useParams<{ analysisId }>();

  const navigateToSettings = () => {
    if (!analysisId) return;
    history.push(routes.settingsPage(analysisId));
    dispatch(setAnalysisError(null));
  };

  useEffect(() => {
    if (!errorData[errorType]) {
      history.push(routes.error);
    }
  }, [errorType, history]);

  if (!errorData[errorType]) {
    return null;
  }

  return (
    <StyledAnalysisErrorScreen>
      <img src={errorData[errorType].src} alt={getResource(errorData[errorType].alt)} />
      <StyledTitle>
        <Text resource={errorData[errorType].title} />
      </StyledTitle>
      <StyledDescription>
        <div>
          <Text resource={errorData[errorType].description} />
        </div>
      </StyledDescription>
      <StyledButtonGroup>
        {errorType === PageErrorType.AnalysisNotFound && (
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            onClick={() => {
              history.push(routes.analysisListPage);
              dispatch && dispatch(setAnalysisError(null));
            }}
          >
            <Text resource="button.goToAnalysisList" />
            <Icon type={IconType.chevronRight} size={12} />
          </Button>
        )}
        {errorType === PageErrorType.AnalysisNotAnalyzed ||
        (errorType === PageErrorType.AnalysisFailed && isAnyAdmin(userRole)) ? (
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            onClick={navigateToSettings}
          >
            <Text resource="button.goToAnalysisSettings" />
            <Icon type={IconType.chevronRight} size={12} style={{ marginTop: "2px" }} />
          </Button>
        ) : (
          <Button
            variant={ButtonVariant.secondary}
            size={ButtonSize.sm}
            onClick={() => {
              history.push(routes.homePage);
              dispatch && dispatch(setAnalysisError(null));
            }}
          >
            <Text resource="button.goToHomepage" />
            <Icon type={IconType.chevronRight} size={12} style={{ marginTop: "2px" }} />
          </Button>
        )}
      </StyledButtonGroup>
    </StyledAnalysisErrorScreen>
  );
};

const StyledAnalysisErrorScreen = styled.div`
  height: calc(100vh - 60px - 75px - 5px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledTitle = styled.div`
  font-size: 21px;
  font-weight: bold;
  color: ${Color.gray50};
  margin-top: 15px;
`;

const StyledDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: ${Color.gray40};
  margin-top: 8px;

  display: flex;
  flex-direction: column;
  gap: 0.2em;
`;

const StyledButtonGroup = styled.div`
  display: flex;
  gap: 9px;
  margin-top: 12px;
`;
