import React, { useEffect, useContext, useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { routes } from "routes";

import { useAppDispatch, useAppSelector } from "store";
import {
  clearEditSharingBlockState,
  setAllowExportData,
  setAllowPin,
  setCommentExplorerAccess,
  setDashboardAccess,
  setEditGroupModalView,
  setIsLoading,
  setIsModalOpen,
  setManualRedactionPermission,
  setSelectedCustomConditions,
  setSelectedDemographics,
  setSelectedPermissionLevel,
  setSelectedRedactionCustomConditions,
  setSelectedRedactionVersion,
  setSelectedUsersCount,
  setShowCustomConditionError,
  setShowRedactionCustomConditionError,
  setTopicExplorerAccess,
  setVisibleDemographicsFilters,
} from "store/sharing/editSharingBlockSlice";
import { fetchSharingDraftBySharingId, saveSharingDraftBySharingId } from "store/sharing/thunks";

import { showToastError } from "store/toast/toastSlice";

import { getUrlQueryString } from "utils/getUrlQueryString";
import { isOneCustomConditionEmpty } from "utils/sharing";

import { getUsersInSharingDraft, saveSharingBySharingId } from "services/analysis/sharing";

import {
  setAnalysisError,
  setLoadingPreviewUsers,
  setPreviewUser,
  setPreviewUsers,
  setSelectedModelId,
} from "context/AnalysisContext/actions";
import { AnalysisContext } from "context/AnalysisContext";

import { PermissionForm } from "./PermissionForm";
import { UserSelectionPopup } from "./UserSelectionPopup";

import {
  ManualRedactionPermission,
  PermissionLevel,
  SharingCommentExplorerAccess,
  SharingDashboardAccess,
  SharingTopicExplorerAccess,
} from "@explorance/mly-types";
import { DemographicCustomCondition, RedactionCustomCondition } from "ts/sharing";
import { DemographicFilter, DemographicFilterHeader } from "ts/filters/demographicFilter";
import { EditGroupModalView } from "ts/enums/editGroupModalView";
import { RadioOption } from "ts/radio";
import { PageErrorType } from "ts/enums/pageErrorType";

type Props = {
  show: boolean;
  sharingId: number;
  usersSharedWithCount?: number;
  showEditAddUsers?: boolean;
  groupName?: string;
  refetch: () => void;
  closeShowEditSharing?: () => void;
};

export const EditSharingBlock = ({
  show,
  sharingId,
  usersSharedWithCount,
  showEditAddUsers = false,
  groupName = null,
  refetch,
  closeShowEditSharing,
}: Props) => {
  const dispatch = useAppDispatch();
  const sharing = useAppSelector((state) => state.sharing);
  const editSharingBlockState = useAppSelector((state) => state.editSharingBlock);
  const sharedWithState = useAppSelector((state) => state.sharedWith);
  const settingsState = useAppSelector((state) => state.settings);

  const [state, analysisContextDispatch] = useContext(AnalysisContext);

  const blockRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);
  const history = useHistory();

  // Functions
  const updateSelectedDemographics = (demographics: DemographicFilter[]) => {
    dispatch(setSelectedDemographics(demographics));
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const updateDemographicCustomConditions = (customConditions: DemographicCustomCondition[]) => {
    dispatch(setSelectedCustomConditions(customConditions));
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const updateManualRedactionPermission = (selectedManualRedactionPermission: RadioOption) => {
    const key = Object.keys(ManualRedactionPermission).find(
      (key) => ManualRedactionPermission[key] === selectedManualRedactionPermission.value
    );

    dispatch(setManualRedactionPermission(ManualRedactionPermission[key]));
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const updateRedactionCustomConditions = (
    redactionCustomConditions: RedactionCustomCondition[]
  ) => {
    dispatch(setSelectedRedactionCustomConditions(redactionCustomConditions));
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const updateSelectedRedactionVersion = (selectedRedactionVersionId: string) => {
    const selectedVersion = selectedRedactionVersionId
      ? sharing.redactionVersions.find((version) => `${version.id}` === selectedRedactionVersionId)
      : null;

    dispatch(setSelectedRedactionVersion(selectedVersion));
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const handleSelectDashboardAccess = () => {
    dispatch(
      setDashboardAccess(
        editSharingBlockState.dashboardAccess === SharingDashboardAccess.Hidden
          ? SharingDashboardAccess.Shared
          : SharingDashboardAccess.Hidden
      )
    );
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const handleSelectTopicExplorerAccess = () => {
    dispatch(
      setTopicExplorerAccess(
        editSharingBlockState.topicExplorerAccess === SharingTopicExplorerAccess.Hidden
          ? SharingTopicExplorerAccess.Shared
          : SharingTopicExplorerAccess.Hidden
      )
    );
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const handleAllowPin = () => {
    dispatch(setAllowPin(!editSharingBlockState.allowPin));
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const handleSelectCommentExplorerAccess = () => {
    dispatch(
      setCommentExplorerAccess(
        editSharingBlockState.commentExplorerAccess === SharingCommentExplorerAccess.Hidden
          ? SharingCommentExplorerAccess.Shared
          : SharingCommentExplorerAccess.Hidden
      )
    );
    dispatch(
      setAllowExportData(
        editSharingBlockState.commentExplorerAccess === SharingCommentExplorerAccess.Shared
          ? false
          : editSharingBlockState.allowExportData
      )
    );
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const handleSelectPermissionLevel = (permissionLevel: PermissionLevel) => {
    dispatch(setSelectedPermissionLevel(permissionLevel));
    dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
  };

  const applyEditChanges = async () => {
    let hasError;
    if (isOneCustomConditionEmpty(editSharingBlockState.selectedCustomConditions)) {
      dispatch(setShowCustomConditionError(true));
      hasError = true;
    }

    if (isOneCustomConditionEmpty(editSharingBlockState.selectedRedactionCustomConditions)) {
      dispatch(setShowRedactionCustomConditionError(true));
      hasError = true;
    }

    if (hasError) return;

    dispatch(setIsLoading(true));
    try {
      await saveSharingBySharingId(sharingId);
      closeShowEditSharing();
      refetch();
    } catch (error: any) {
      dispatch(showToastError(error.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  const navigateToPreview = async () => {
    let userId;

    await getUsersInSharingDraft({ sharingId: sharingId })
      .then(({ data }) => {
        if (!data.users[0]) {
          dispatch(setAnalysisError(PageErrorType.NoUsersSelected));
          return;
        }

        userId = data.users[0].id;
        analysisContextDispatch(setPreviewUsers(data.users));
        analysisContextDispatch(setPreviewUser(data.users[0]));
        analysisContextDispatch(setLoadingPreviewUsers(false));
      })
      .catch((e) => console.error(e.message));

    // reset custom model id if not currently selected for preview page
    if (
      state.analysisDetails.selectedModel?.customModelId &&
      state.selectedModelId !== state.analysisDetails.selectedModel.customModelId
    ) {
      analysisContextDispatch(
        setSelectedModelId(state.analysisDetails.selectedModel.customModelId)
      );
    }

    const queryString = getUrlQueryString({
      sharingPreview: sharing.view,
      step: sharing.step,
      sharingId,
      expandedRowId: sharedWithState.expandedRowId,
      sharedWithPage: sharedWithState.currentPage,
      userId,
    });

    const previewPageUrl = settingsState.features.llm
      ? routes.summaryPage(state.analysisDetails.id, queryString)
      : routes.overviewPage(state.analysisDetails.id, queryString);

    history.push(previewPageUrl);
  };

  const handleOpenEditGroupModal = (view: EditGroupModalView) => {
    dispatch(setEditGroupModalView(view));
    dispatch(setIsModalOpen(true));
  };

  useEffect(() => {
    if (usersSharedWithCount) dispatch(setSelectedUsersCount(usersSharedWithCount));
    dispatch(
      fetchSharingDraftBySharingId({
        sharingId,
        availableDemographicFilters: state.availableDemographicFilters,
      })
    );
    return () => {
      dispatch(clearEditSharingBlockState());
    };
  }, []); // eslint-disable-line

  // Props declarations
  const permissionLevelProps = {
    selectedPermissionLevel: editSharingBlockState.selectedPermissionLevel,
    handleSelectPermissionLevel,
  };

  const visibleAnalysisDataProps = {
    selectedDemographics: editSharingBlockState.selectedDemographics,
    selectedCustomConditions: editSharingBlockState.selectedCustomConditions,
    showCustomConditionError: editSharingBlockState.showCustomConditionError,
    userDemographics: sharing.allUserDemographics,
    updateSelectedDemographics,
    updateDemographicCustomConditions,
    handleHideCustomConditionError: () => dispatch(setShowCustomConditionError(false)),
  };

  const visibleRedactionVersionProps = {
    selectedCustomConditions: editSharingBlockState.selectedRedactionCustomConditions,
    selectedRedactionVersion: editSharingBlockState.selectedRedactionVersion,
    userDemographics: sharing.allUserDemographics,
    showRedactionCustomConditionError: editSharingBlockState.showRedactionCustomConditionError,
    selectedManualRedactionPermission: editSharingBlockState.manualRedactionPermission,
    updateManualRedactionPermission,
    updateRedactionCustomConditions,
    updateSelectedRedactionVersion,
    handleHideRedactionCustomConditionError: () =>
      dispatch(setShowRedactionCustomConditionError(false)),
  };
  const visibleDemographicsDataProps = {
    savedDemHeaders: editSharingBlockState.visibleDemographicsFilters,
    updateSavedDemHeaders: (updatedHeaders: DemographicFilterHeader[]) => {
      dispatch(setVisibleDemographicsFilters(updatedHeaders));
      dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
    },
  };

  const dashboardSettingsDataProps = {
    dashboardAccess: editSharingBlockState.dashboardAccess,
    topicExplorerAccess: editSharingBlockState.topicExplorerAccess,
    commentExplorerAccess: editSharingBlockState.commentExplorerAccess,
    allowExportData: editSharingBlockState.allowExportData,
    allowPin: editSharingBlockState.allowPin,
    handleSelectDashboardAccess,
    handleSelectTopicExplorerAccess,
    handleSelectCommentExplorerAccess,
    handleAllowPin,
    handleToggleExportData: () => {
      if (
        editSharingBlockState.commentExplorerAccess === SharingCommentExplorerAccess.Hidden &&
        !editSharingBlockState.allowExportData
      ) {
        dispatch(setCommentExplorerAccess(SharingCommentExplorerAccess.Shared));
      }
      dispatch(setAllowExportData(!editSharingBlockState.allowExportData));
      dispatch(saveSharingDraftBySharingId({ sharingId, groupName }));
    },
  };

  if (!editSharingBlockState.allDataLoaded) return null;

  return (
    <StyledEditSharingBlock show={show} ref={blockRef}>
      {show && (
        <PermissionForm
          showPermissionFields={
            editSharingBlockState.selectedPermissionLevel === PermissionLevel.View
          }
          analysisDemographicHeaders={sharing.analysisDemographicHeaders}
          // props for each section of the form have the following info: state, handler functions
          permissionLevelData={permissionLevelProps}
          visibleAnalysisData={visibleAnalysisDataProps}
          visibleDemographicsData={visibleDemographicsDataProps}
          visibleRedactionVersionData={visibleRedactionVersionProps}
          dashboardSettingsData={dashboardSettingsDataProps}
          editSharing={true}
          showEditAddUsers={showEditAddUsers}
          usersSharedWithCount={editSharingBlockState.selectedUsersCount}
          closeEditSharing={closeShowEditSharing}
          applyEditChanges={applyEditChanges}
          navigateToPreview={navigateToPreview}
          openEditGroupModal={handleOpenEditGroupModal}
          isLoading={editSharingBlockState.isLoading}
        />
      )}
      {editSharingBlockState.isModalOpen && <UserSelectionPopup sharingId={sharingId} />}
    </StyledEditSharingBlock>
  );
};

const StyledEditSharingBlock = styled.div<{ show: boolean }>`
  width: ${({ show }) => (show ? "100%" : "0")};
  margin-top: ${({ show }) => show && "18px"};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: all 0.3s ease-in-out;
  height: ${({ show }) => (show ? "auto" : "0")};
  scroll-margin: 90px;
  padding: 15px;
`;
