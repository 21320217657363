import React, { useEffect } from "react";
import styled, { css } from "styled-components";

import {
  redactComment,
  revertCommentRedaction,
  submitRedactionRequest,
} from "store/redaction/thunks";
import { useAppDispatch, useAppSelector } from "store";
import {
  closeRedactCommentModal,
  toggleHideComment,
  toggleRemoveComment,
  setRedactedCommentValue,
  setRevertCommentModalOpen,
} from "store/redaction/redactCommentSlice";

import { useResource } from "hooks/useResource";

import { Button } from "components/_buttons/Button";
import { CloseButton } from "components/_buttons/CloseButton";
import { Icon, IconType } from "components/_icons/Icon";
import { Modal } from "components/_modals/Modal";
import { Text } from "components/Text";
import { ActionConfirmationModal } from "components/_modals/ActionConfirmationModal";
import { LoadingDots } from "components/LoadingDots";

import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";
import { RedactCommentModalType } from "ts/redactCommentModalType";
import { RedactionDisplayMethod } from "@explorance/mly-types";
import { useQueryParams } from "hooks/useQueryParams";

const PARENT_MODAL_MARGIN_Y = "10%";

type Props = {
  type: RedactCommentModalType;
  onRedactionSuccess: () => void;
};

export const RedactCommentModal = ({ type, onRedactionSuccess }: Props) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.redactComment);
  const { sharingPreview } = useQueryParams();

  const { getResource } = useResource();

  useEffect(() => {
    return () => {
      dispatch(closeRedactCommentModal());
    };
  }, [dispatch]);

  const handleChangeRedactedComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setRedactedCommentValue(e.target.value));
  };

  const handleRedactComment = async () => {
    const action =
      type === RedactCommentModalType.Manual ? redactComment() : submitRedactionRequest();
    await dispatch(action).unwrap();
    await onRedactionSuccess();
  };

  const handleRevertToOriginalComment = async () => {
    await dispatch(revertCommentRedaction()).unwrap();
    await onRedactionSuccess();
  };

  const submissionResourceKey = {
    [RedactCommentModalType.Manual]: "button.apply",
    [RedactCommentModalType.Request]: "comments.redactModal.button.requestRedaction",
  }[type];

  const titleResourceKey = {
    [RedactCommentModalType.Manual]: "comments.redactModalManual.header",
    [RedactCommentModalType.Request]: "comments.redactModalRequest.header",
  }[type];

  const isHidden = state.displayMethod === RedactionDisplayMethod.Total;
  const isRemoved = state.displayMethod === RedactionDisplayMethod.Remove;
  const isPartial = state.displayMethod === RedactionDisplayMethod.Partial;

  const isRedactCommentButtonDisabled =
    state.isRevertingComment ||
    (isPartial && state.redactedCommentValue === state.originalCommentValue) ||
    (state.redactedCommentValue.trim().length === 0 && !isHidden && !isRemoved) ||
    !!sharingPreview;

  const isRevertCommentButtonDisabled = state.isRedactingComment || !!sharingPreview;

  return (
    <>
      <Modal
        isOpen={state.isRedactCommentModalOpen}
        width="848px"
        height="auto"
        padding="20px 24px 24px 24px"
        margin={`${PARENT_MODAL_MARGIN_Y} auto`}
        handleRequestCloseFunc={() => dispatch(closeRedactCommentModal())}
      >
        <StyledHeaderSection>
          <h2>
            <Text resource={titleResourceKey} />
          </h2>
          <CloseButton
            titleKey="button.close"
            closeHandler={() => dispatch(closeRedactCommentModal())}
          />
        </StyledHeaderSection>
        {state.isLoadingCommentRedaction ? (
          <StyledLoadingDotsContainer>
            <LoadingDots />
          </StyledLoadingDotsContainer>
        ) : (
          <>
            <StyledOriginalCommentSection>
              <h3>
                <Text resource="comments.redactModal.originalComment.header" />
              </h3>
              <div>
                <p>{state.originalCommentValue}</p>
              </div>
            </StyledOriginalCommentSection>
            <StyledRedactedCommentHeaderSection>
              <h3>
                <Text resource="comments.redactModal.redactedComment.header" />
              </h3>
              <StyledRedactionOptions>
                <Button
                  variant={isHidden ? ButtonVariant.secondary : ButtonVariant.outlineGray}
                  size={ButtonSize.sm}
                  onClick={() =>
                    dispatch(
                      toggleHideComment(
                        getResource("comments.redactModal.redactedComment.redacted")
                      )
                    )
                  }
                >
                  <Icon type={IconType.eyeMinus} size={12} style={{ marginRight: 7 }} />
                  <Text resource="comments.redactModal.button.hideComment" />
                </Button>
                <Button
                  variant={isRemoved ? ButtonVariant.secondary : ButtonVariant.outlineGray}
                  size={ButtonSize.sm}
                  onClick={() => dispatch(toggleRemoveComment())}
                >
                  <Icon type={IconType.minusCircle} style={{ marginRight: 7 }} size={14} />
                  <Text resource="comments.redactModal.button.removeComment" />
                </Button>
              </StyledRedactionOptions>
            </StyledRedactedCommentHeaderSection>
            {isRemoved ? (
              <StyledRemovedCommentBanner>
                <Text resource="comments.redactModal.redactedComment.removed" />
              </StyledRemovedCommentBanner>
            ) : (
              <StyledRedactedCommentInput
                value={state.redactedCommentValue}
                onChange={handleChangeRedactedComment}
              />
            )}
            <StyledSubmissionSection>
              <Button
                variant={ButtonVariant.light}
                size={ButtonSize.ml}
                onClick={() => dispatch(setRevertCommentModalOpen(true))}
                loading={state.isRevertingComment}
                disabled={isRevertCommentButtonDisabled}
              >
                <Text resource="comments.redactModal.button.revertComment" />
              </Button>

              <Button
                variant={ButtonVariant.primary}
                size={ButtonSize.ml}
                onClick={handleRedactComment}
                loading={state.isRedactingComment}
                disabled={isRedactCommentButtonDisabled}
              >
                <Text resource={submissionResourceKey} />
              </Button>
            </StyledSubmissionSection>
          </>
        )}
      </Modal>
      <ActionConfirmationModal
        isOpen={state.isRevertCommentModalOpen}
        title={<Text resource="comments.redactModal.revertModal.title" />}
        caption={<Text resource="comments.redactModal.revertModal.description" />}
        buttonSize={ButtonSize.lg}
        actionButtonLabel={<Text resource="comments.redactModal.revertModal.button" />}
        actionButtonVariant={ButtonVariant.primary}
        onCancel={() => dispatch(setRevertCommentModalOpen(false))}
        onClickActionButton={handleRevertToOriginalComment}
        margin={`calc(${PARENT_MODAL_MARGIN_Y} + 88px) auto`}
      />
    </>
  );
};

const StyledHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;
  color: ${Color.gray50};

  h2 {
    font-size: 20px;
    margin: 0;
  }
`;

const StyledOriginalCommentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    font-size: 16px;
    margin: 0;
    color: ${Color.gray50};
  }

  p {
    font-size: 14px;
    margin: 0;
    color: ${Color.gray40};
    font-size: 14px;
  }

  div {
    padding: 8px 10px;
    border-radius: 5px;
    background-color: ${Color.neutral20};
    max-height: 150px;
    overflow-y: auto;
  }
`;

export const StyledRedactionOptions = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 10px 0;
`;

export const StyledRedactedCommentHeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${Color.gray50};

  h3 {
    font-size: 16px;
    margin: 0;
  }
`;

export const StyledRemovedCommentBanner = styled.div`
  background-color: ${Color.red10};
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px;
  text-align: center;
  padding: 8px;
`;

export const StyledRedactedCommentInput = styled.textarea<{
  isHidden?: boolean;
  isWrapped?: boolean;
}>`
  padding: 10px;
  height: ${({ isWrapped }) => (isWrapped ? 50 : 120)}px;
  width: 100%;
  border: 1px solid ${Color.sky50};
  border-radius: 2px;
  resize: none;
  color: ${Color.gray50};
  overflow-y: auto;
  ${({ isHidden }) =>
    isHidden &&
    css`
      background-color: ${Color.neutral20};
    `}

  &:focus {
    outline: 1px solid ${Color.blue50};
  }
`;

const StyledSubmissionSection = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
  gap: 13px;
`;

const StyledLoadingDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 271px;
`;
