import React, { useRef, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "store";
import styled from "styled-components";

import { updateErrorLabels } from "store/editVariableMapping/editVariableMappingSlice";
import { Button } from "components/_buttons/Button";
import { Icon, IconType } from "components/_icons/Icon";

import { VariableMappingGroupItem } from "ts/variableMappings";
import { ButtonSize, ButtonVariant } from "ts/enums/button";
import { Color } from "ts/enums/color";

type Props = {
  mappingIndex: number;
  deleteRow(rowId: number): void;
  updateVariable(newVariable: string, index: number): void;
  updateDefinition(newDefinition: string, index: number): void;
};

const inputMaxLength = 100;

export const VariableMappingDefinitionListRow = ({
  mappingIndex,
  deleteRow,
  updateVariable,
  updateDefinition,
}: Props) => {
  // redux
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state.editVariableMapping);

  // hooks
  const [isRowFocused, setIsRowFocused] = useState<boolean>(false);
  const [isDeleteHovered, setIsDeleteHovered] = useState<boolean>(false);
  const [hasDuplicateVariable, setHasDuplicateVariable] = useState<boolean>(false);
  const [hasVariableError, setHasVariableError] = useState<boolean>(false);
  const [hasDefinitionError, setHasDefinitionError] = useState<boolean>(false);

  const leftInputRef = useRef<HTMLInputElement>();
  const rightInputRef = useRef<HTMLInputElement>();

  // variables
  const mapping: VariableMappingGroupItem = state.updatedMappings[mappingIndex];

  // functions
  const onChangeVariable = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVariable = e.currentTarget.value;
    updateVariable(newVariable, index);
    checkIfVariableIsDuplicate();
  };

  const onChangeDefinition = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDefinition = e.currentTarget.value;
    updateDefinition(newDefinition, index);
  };

  const onBlurVariableInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const newVariable = e.currentTarget.value.trim();
    updateVariable(newVariable, mappingIndex);
    validateMappingValues(newVariable, mapping.definition);
    checkIfVariableIsDuplicate();
    onBlurInput();
    dispatch(updateErrorLabels());
  };

  const onBlurDefinitionInput = (e: React.FocusEvent<HTMLInputElement>) => {
    const newDefinition = e.currentTarget.value.trim();
    updateDefinition(newDefinition, mappingIndex);
    validateMappingValues(mapping.variable, newDefinition);
    onBlurInput();
    dispatch(updateErrorLabels());
  };

  const onBlurInput = () => {
    setIsRowFocused(false);
    setIsDeleteHovered(false);
  };

  const onClickDeleteButton = () => {
    setHasVariableError(false);
    setHasDefinitionError(false);
    setHasDuplicateVariable(false);
    deleteRow(mappingIndex);
  };

  const validateMappingValues = (variable: string, definition: string) => {
    if (variable.length > 0 && definition.length === 0) setHasDefinitionError(true);
    else if (variable.length === 0 && definition.length > 0) setHasVariableError(true);
    else if (
      (variable.length > 0 && definition.length > 0) ||
      (variable.length === 0 && definition.length === 0)
    ) {
      setHasDefinitionError(false);
      setHasVariableError(false);
    }
  };

  const checkIfVariableIsDuplicate = () => {
    const variableArray: string[] = state.updatedMappings
      .map((item) => item.variable.toLocaleLowerCase())
      .filter((item) => item.length > 0);

    variableArray.forEach(() => {
      if (
        variableArray.indexOf(mapping.variable.toLocaleLowerCase()) !==
        variableArray.lastIndexOf(mapping.variable.toLocaleLowerCase())
      )
        return setHasDuplicateVariable(true);
      else {
        return setHasDuplicateVariable(false);
      }
    });
  };

  useEffect(() => {
    const handleMouseDown = (e: globalThis.MouseEvent) => {
      if (
        leftInputRef.current &&
        rightInputRef &&
        !leftInputRef.current.contains(e.target as HTMLInputElement) &&
        !rightInputRef.current.contains(e.target as HTMLInputElement)
      ) {
        setIsRowFocused(false);
      }
    };
    document.addEventListener("mousedown", handleMouseDown);

    return () => document.removeEventListener("mousedown", handleMouseDown);
  });

  useEffect(() => {
    validateMappingValues(mapping.variable, mapping.definition);
    checkIfVariableIsDuplicate();
    dispatch(updateErrorLabels());
  }, [mapping.variable, mapping.definition]); // eslint-disable-line

  return (
    <StyledDefinitionListRow>
      <StyledInputContainer>
        <StyledLeftInput
          ref={leftInputRef}
          max={inputMaxLength}
          value={mapping.variable}
          onChange={onChangeVariable(mappingIndex)}
          onFocus={() => setIsRowFocused(true)}
          onBlur={onBlurVariableInput}
          hasError={state.showErrors && hasVariableError}
          isDuplicate={state.showErrors && hasDuplicateVariable}
        />
        <StyledArrowIconContainer>
          <Icon type={IconType.arrowRightThin} color={isRowFocused ? Color.blue50 : Color.gray20} />
        </StyledArrowIconContainer>
        <StyledRightInput
          ref={rightInputRef}
          max={inputMaxLength}
          value={mapping.definition}
          onChange={onChangeDefinition(mappingIndex)}
          onFocus={() => setIsRowFocused(true)}
          onBlur={onBlurDefinitionInput}
          hasError={state.showErrors && hasDefinitionError}
        />
      </StyledInputContainer>
      <StyledDeleteIconContainer
        onMouseOver={() => setIsDeleteHovered(true)}
        onMouseLeave={() => setIsDeleteHovered(false)}
      >
        {(isRowFocused || isDeleteHovered) && (
          <Button
            onClick={onClickDeleteButton}
            variant={ButtonVariant.light}
            size={ButtonSize.sm}
            square
          >
            <Icon type={IconType.x} color={Color.gray50} size={12} />
          </Button>
        )}
      </StyledDeleteIconContainer>
    </StyledDefinitionListRow>
  );
};

const StyledDefinitionListRow = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  font-weight: bold;
  padding: 0px;
  position: relative;
  @media (max-width: 1365px) {
    width: 890px;
  }

  @media (min-width: 1366px) {
    width: 1140px;
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledArrowIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 35px;
`;

const StyledInput = styled.input`
  height: 33px;
  border-radius: 2px;
  padding: 0px 12px;
  width: 500px;
  border: 1px solid ${Color.blue20};

  &:focus {
    outline-style: none;
    border: 1px solid ${Color.blue50};
  }

  @media (max-width: 1365px) {
    width: 350px;
  }
`;

const StyledLeftInput = styled(StyledInput)<{ isDuplicate: boolean; hasError: boolean }>`
  color: ${Color.black};
  border: ${({ hasError, isDuplicate }) =>
    hasError || isDuplicate ? `1px solid ${Color.red30}` : `1px solid ${Color.blue20}`};

  &:focus {
    border: ${({ hasError, isDuplicate }) =>
      (hasError || isDuplicate) && `1px solid ${Color.red30}`};
  }
`;

const StyledRightInput = styled(StyledInput)<{ hasError: boolean }>`
  border: ${({ hasError }) =>
    hasError ? `1px solid ${Color.red30}` : `1px solid ${Color.blue20}`};

  &:focus {
    border: ${({ hasError }) => hasError && `1px solid ${Color.red30}`};
  }
`;

const StyledDeleteIconContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  width: 30px;
  height: 35px;
  transform: translateX(175%);
`;
