import { RedactionDisplayMethod } from "@explorance/mly-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchCommentRedactionDetails } from "./thunks";

type RedactCommentState = {
  // data
  commentId: number;
  originalCommentValue: string;
  redactedCommentValue: string;
  originalRedactedCommentValue: string;
  displayMethod: RedactionDisplayMethod;
  // ui
  isRedactCommentModalOpen: boolean;
  isRevertCommentModalOpen: boolean;
  isLoadingCommentRedaction: boolean;
  isRedactingComment: boolean;
  isRevertingComment: boolean;
};

const initialState: RedactCommentState = {
  // data
  commentId: null,
  originalCommentValue: "",
  redactedCommentValue: "",
  originalRedactedCommentValue: "",
  displayMethod: RedactionDisplayMethod.Partial,
  // ui
  isRedactCommentModalOpen: false,
  isRevertCommentModalOpen: false,
  isLoadingCommentRedaction: false,
  isRedactingComment: false,
  isRevertingComment: false,
};

const RedactCommentSlice = createSlice({
  name: "redactCommentSlice",
  initialState,
  reducers: {
    openRedactCommentModal: (state, action) => {
      state.commentId = action.payload;
      state.isRedactCommentModalOpen = true;
    },
    closeRedactCommentModal: () => initialState,
    setRevertCommentModalOpen: (state, action) => {
      state.isRevertCommentModalOpen = action.payload;
    },
    setRedactedCommentValue: (state, action) => {
      state.redactedCommentValue = action.payload;
    },
    setIsRedactingComment: (state, action) => {
      state.isRedactingComment = action.payload;
    },
    setIsRevertingComment: (state, action) => {
      state.isRevertingComment = action.payload;
    },
    toggleHideComment: (state, action: PayloadAction<string>) => {
      state.displayMethod =
        state.displayMethod === RedactionDisplayMethod.Total
          ? RedactionDisplayMethod.Partial
          : RedactionDisplayMethod.Total;
      if (state.displayMethod === RedactionDisplayMethod.Total) {
        state.redactedCommentValue = action.payload;
      } else {
        state.redactedCommentValue = state.originalRedactedCommentValue;
      }
    },
    toggleRemoveComment: (state) => {
      state.displayMethod =
        state.displayMethod === RedactionDisplayMethod.Remove
          ? RedactionDisplayMethod.Partial
          : RedactionDisplayMethod.Remove;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommentRedactionDetails.fulfilled, (state, action) => {
      state.displayMethod = action.payload.redactionDisplayMethod ?? RedactionDisplayMethod.Partial;
      state.redactedCommentValue = [
        RedactionDisplayMethod.Partial,
        RedactionDisplayMethod.Total,
      ].includes(action.payload.redactionDisplayMethod)
        ? action.payload.redactionValue
        : action.payload.originalValue;
      state.originalRedactedCommentValue = [RedactionDisplayMethod.Partial].includes(
        action.payload.redactionDisplayMethod
      )
        ? action.payload.redactionValue
        : action.payload.originalValue;
      state.originalCommentValue = action.payload.originalValue;
      state.isLoadingCommentRedaction = false;
    });
  },
});

export const {
  openRedactCommentModal,
  closeRedactCommentModal,
  setRevertCommentModalOpen,
  setRedactedCommentValue,
  toggleHideComment,
  toggleRemoveComment,
  setIsRedactingComment,
  setIsRevertingComment,
} = RedactCommentSlice.actions;

export default RedactCommentSlice.reducer;
