import { createAsyncThunk } from "@reduxjs/toolkit";

import { Category, Tag } from "@explorance/mly-types";
import { getTags } from "services/tags";
import { DEFAULT_CATEGORY } from "assets/constants/defaultValues";
import { getCategories } from "services/categories";
export const fetchCategories = createAsyncThunk<Category[], void>(
  "pinComment/fetchCategories",
  async (): Promise<Category[]> => {
    const { data } = await getCategories();

    if (!data) {
      throw new Error("Failed to fetch categories.");
    }
    //Make sure "Favorite" category is on top
    const reorderedCategories = [
      { name: DEFAULT_CATEGORY, id: null },
      ...data.categories.filter((cat) => cat.name !== DEFAULT_CATEGORY),
    ];
    return reorderedCategories;
  }
);

export const fetchTags = createAsyncThunk<Tag[], void>(
  "pinComment/fetchTags",
  async (): Promise<Tag[]> => {
    const { data } = await getTags();
    if (!data) {
      throw new Error("Failed to fetch tags.");
    }

    return data.tags;
  }
);
