// library
import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";

// pages
import { ErrorPage } from "pages/error";
import { OverviewPage } from "pages/analysis/[id]/overview";
import { DashboardPage } from "pages/analysis/[id]/dashboard";
import { TopicsPage } from "pages/analysis/[id]/topics";
import { CommentsPage } from "pages/analysis/[id]/comments";
import { CustomModelNewPage } from "pages/custom-models/new";
import { CustomModelEditPage } from "pages/custom-models/[id]/edit";
import { AnalysisListPage } from "pages/analysis";
import { CustomModelsPage } from "pages/custom-models";
import { RedactionList } from "pages/redaction";
import { RedactionVersionsList } from "pages/redactionVersions";
import { RedactionRequestList } from "pages/redaction/requests/index";
import { VariableMappingEditPage } from "pages/variable-mappings/[id]";
import { VariableMappingsPage } from "pages/variable-mappings";
import { DownloadedFilesPage } from "pages/downloaded-files";
import { HomePage } from "pages/home";
import { UserListPage } from "pages/user-management/user-list";
import { CreateProfilePage } from "pages/user-management/create-profile";
import { UpdateProfilePage } from "pages/user-management/update-profile/[id]";
import { SettingsPage } from "pages/analysis/[id]/settings";
import { SharingPage } from "pages/analysis/[id]/sharing";
import { SummaryPage } from "pages/analysis/[id]/summary";

// components
import { RouteWrapper } from "components/RouteWrapper";
import { AnalysisLayout } from "common-layouts/AnalysisLayout";
import { LicensingPage } from "pages/licensing";
import { ModelManagementPage } from "pages/model-management";
import { EditRedactionPage } from "pages/redaction/[id]/edit";
import { NewRedactionPage } from "pages/redaction/new";

export const routes = {
  // General
  error: "/error",
  homePage: "/home",
  downloadedFilesPage: "/downloaded-files",
  welcomePage: "/welcome",

  // Analysis
  analysisListPage: "/analysis",
  summaryPage: (analysisId: number, query?: string) =>
    `/analysis/${analysisId}/summary${query || ""}`,
  overviewPage: (analysisId: number, query?: string) =>
    `/analysis/${analysisId}/overview${query || ""}`,
  dashboardPage: (analysisId: number, query?: string) =>
    `/analysis/${analysisId}/dashboard${query || ""}`,
  topicsPage: (analysisId: number, query?: string) =>
    `/analysis/${analysisId}/topics${query || ""}`,
  commentsPage: (analysisId: number, query?: string) =>
    `/analysis/${analysisId}/comments${query || ""}`,
  settingsPage: (analysisId: number) => `/analysis/${analysisId}/settings`,
  sharingPage: (analysisId: number, query?: string) =>
    `/analysis/${analysisId}/sharing${query || ""}`,

  // Customize
  customizeCustomModelsPage: "/custom-models",
  newCustomModelPage: "/custom-models/new",
  editCustomModelPage: (customModelId) => `/custom-models/${customModelId}/edit`,
  customizeVariableMappingsPage: "/variable-mapping-groups",
  editSelectedVariableMapping: (mappingId: number | string) =>
    `/variable-mapping-groups/${mappingId}`,
  redactionListPage: "/redaction/list",
  createRedaction: "/redaction/new",
  editRedactionPage: (redactionId) => `/redaction/${redactionId}/edit`,
  redactionRequestList: "/redaction/requests",
  redactionVersionsList: "/redaction/versions",

  // Admin
  userListPage: "/user-management/user-list",
  createProfilePage: "/user-management/create-profile",
  updateProfilePath: "/user-management/update-profile",
  updateProfilePage: (userId: number) => `/user-management/update-profile/${userId}`,
  licensingPage: "/licensing",

  // SuperAdmin:
  modelManagementPage: "/model-management",
};

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>

      <Redirect exact from="/index.html" to="/home" />

      <Route path={routes.error}>
        <ErrorPage />
      </Route>

      <Route path={routes.homePage}>
        <HomePage />
      </Route>

      <Route exact path={routes.analysisListPage}>
        <AnalysisListPage />
      </Route>

      <RouteWrapper
        path="/analysis/:analysisId/settings"
        component={SettingsPage}
        layout={AnalysisLayout}
      />

      <RouteWrapper
        path="/analysis/:analysisId/summary"
        component={SummaryPage}
        layout={AnalysisLayout}
      />

      <RouteWrapper
        path="/analysis/:analysisId/overview"
        component={OverviewPage}
        layout={AnalysisLayout}
      />

      <RouteWrapper
        path="/analysis/:analysisId/dashboard"
        component={DashboardPage}
        layout={AnalysisLayout}
      />
      <RouteWrapper
        path="/analysis/:analysisId/topics"
        component={TopicsPage}
        layout={AnalysisLayout}
      />
      <RouteWrapper
        path="/analysis/:analysisId/comments"
        component={CommentsPage}
        layout={AnalysisLayout}
      />

      <RouteWrapper
        path="/analysis/:analysisId/sharing"
        component={SharingPage}
        layout={AnalysisLayout}
      />

      <Route path={routes.userListPage}>
        <UserListPage />
      </Route>

      <Route path={routes.createProfilePage}>
        <CreateProfilePage />
      </Route>

      <Route path="/user-management/update-profile/:userId">
        <UpdateProfilePage />
      </Route>

      <Route path={routes.customizeCustomModelsPage} exact>
        <CustomModelsPage />
      </Route>

      <Route path={routes.customizeVariableMappingsPage} exact>
        <VariableMappingsPage />
      </Route>

      <Route path={routes.redactionListPage} exact>
        <RedactionList />
      </Route>

      <Route path={routes.redactionRequestList} exact>
        <RedactionRequestList />
      </Route>

      <Route path={routes.redactionVersionsList} exact>
        <RedactionVersionsList />
      </Route>

      <Route path="/variable-mapping-groups/:mappingId">
        <VariableMappingEditPage />
      </Route>

      <Route path="/custom-models/new">
        <CustomModelNewPage />
      </Route>

      <Route path="/custom-models/:customModelId/edit">
        <CustomModelEditPage />
      </Route>

      <Route path={routes.downloadedFilesPage}>
        <DownloadedFilesPage />
      </Route>

      <Route path={routes.licensingPage}>
        <LicensingPage />
      </Route>

      <Route path={routes.modelManagementPage}>
        <ModelManagementPage />
      </Route>

      <Route path={routes.createRedaction}>
        <NewRedactionPage />
      </Route>

      <Route path="/redaction/:redactionId/edit">
        <EditRedactionPage />
      </Route>

      <Redirect path="*" to={routes.homePage} />
    </Switch>
  );
};
